#page-detail-community {
  .detail-news {
    margin: 40px 0;
    .header-news {
      margin-bottom: 3rem;
      h2 {
        color: $cl-black;
        font-size: 5rem;
        font-weight: $font-bold;
        line-height: 1.2;
        margin-bottom: 3rem;
        @include media-maxW(960) {font-size: 3.5rem;}
      }
      time {
        width: 50%;
      }
      figure {
        width: 50%;
        text-align: right;
        @include media-maxW(960) {display: none}
        img {
          max-width: 100%;
        }
      }
    }
    .content-news {
      margin-bottom: 3rem;
      img {
        margin-bottom: 1.8rem;
        width: 100%;
      }
      p {
        color: $cl-black;
        font-size: 1.8rem;
        font-weight: $font-light;
        line-height: 1.6;
        margin-bottom: 1.8rem;
        @include media-maxW(960) {
          font-size: 1.4rem;
          margin-bottom: 1.4rem;
        }
      }
    }
    .footer-news {
      border-top: 0.1rem solid #000000;
      border-bottom: 0.1rem solid #000000;
      padding: 25px 0;
      @include media-maxW(960) {padding: 10px 0;}
      .email,
      .phone {
        width:50%;
        font-weight: $font-light;
        img {height: 20px;}
        @include media-maxW(960) {
          width:100%;
          padding: 10px 0;
          text-align: center;
        }
      }
    }
  }

  .shared-news {
    margin-bottom: 40px;
    .title {
      width: 100%;
      text-align: center;
      color: $cl-black;
      font-size: 1.8rem;
      font-weight: $font-light;
      line-height: 1.6;
      @include media-maxW(960) {
        font-size: 1.4rem;
      }
      figure {
        display: inline-block;
        height: 25px;
        margin-right: 10px; 
        @include media-maxW(960) {height: 20px;}     
        img {
          height: 100%;
        }
      }
    }
  }

  .more-news {
    margin-bottom: 40px;
    h3 {
      color: #000000;
      font-size: 2.4rem;
      margin-bottom: 4.5rem;
      @include media-maxW(960) {text-align: center;}
    }
    .flex {
      margin: 0 -15px;
      @include media-maxW(480) {
        margin: 0;
      }
      .item {
        width: 33.33%;
        padding: 0 15px;
        margin-bottom: 30px;
        @include media-maxW(960) {
          width: 100%;
          max-width: 300px;
          margin: 0 auto 30px;
        }
        @include media-maxW(480) {padding: 0;}
        figure {
          width: 100%;
          img {
            width: 100%;
          }
        }
        .title {
          background-color: #e7e7e9;
          color: $cl-black;
          font-size: 2rem;
          padding: 25px;
          text-align: left;
          @include media-maxW(960) {font-size: 1.6rem;}
          .category-name, time {
            font-weight: $font-light;
            line-height: 1.25;
          }
          time {
            display: block;
            margin-bottom: 16px;
          }
          h4 {
            font-weight: $font-bold;
            line-height: 1.5;
          }
        }
      }
    }
    p {
      text-align: center;
      padding-top: 1.5rem;
      a {
        font-size: 1.8rem;
      }
    }
  }
}
