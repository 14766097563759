#page-partner {  
  .partner {
    margin-top: 40px;
    @include media-maxW(480) {margin-top: 20px 0};
    .container {
      .emphasized-block {
        margin: 100px auto;
        @include media-maxW(1200) {margin: 60px 0};
        @include media-maxW(480) {margin: 40px 0};
        .content {
          padding: 20px 0;
          .block-item {
            width: 50%;
            max-width: 450px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 20px auto;
            @include media-maxW(1200) {max-width: 350px};
            @include media-maxW(768) {
              width: 40%; 
            }
            .img {
              width: 35%;
              padding-right: 15px;
              @include media-maxW(768) {
                width: 100%;
                margin: 0 auto 20px;
                padding-right: 0;
              }
              figure {
                width: 100%;
                height: auto;
                position: relative;
                img {
                  width: 100%;
                  height: 100%;
                }
                .normal {
                  position: absolute;
                  opacity: 1;
                }
                .hover {opacity: 0;}
                &:hover {
                  .normal {opacity: 0;}
                  .hover {opacity: 1;}
                }
              }
            }
            .caption {
              width: 65%;
              padding-left: 15px;
              border-left: 1px solid $cl-black;
              line-height: 1.5;
              @include media-maxW(768) {
                display: none;
              }
              h5 {
                font-weight: $font-bold;
                font-size: 1.8rem;
                line-height: 1.2;
                margin-bottom: 10px;
                @include media-maxW(1200) {
                  font-size: 1.5rem;
                }
                @include media-maxW(960) {
                  font-size: 1.4rem;
                  margin-bottom: 5px;
                }
                @include media-maxW(768) {
                  margin-bottom: 20px;
                }
              }
              p {
                font-weight: $font-light;
                font-size: 1.2rem;
                color: $cl-gray;
              }
            }
          }
        }
      }
    }
  }
}