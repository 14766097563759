#page-rule {
  .rule {
    margin: 40px auto;
    @include media-maxW(1200) {margin: 60px 0};
    @include media-maxW(480) {margin: 40px 0};
    .container {
      .head {
        @extend.flex;
        margin: 40px -20px;
        .item {
          width: 50%;
          padding: 0 20px;
          @include media-maxW(768) {padding: 0 5px;}
          .figure-item {
            width: 200px;
            height: 183px;
            background: url(../img/bg/hexagon.jpg) center no-repeat;
            background-size: 100% 100%;
            position: relative;
            margin: 0 auto;
            opacity: 0.5;
            @include media-maxW(767) {
              width: 120px;
              max-width: 100%;
              height: 109px;
            }
            &:after {
              content: "";
              display: block;
              padding-bottom: 100%;
            }
            &.active {
              background: url(../img/bg/hexagon-hover.jpg) center no-repeat;
              background-size: 100% 100%;
              opacity: 1;
            }
            .title-item {
              @extend.center-xy;
              width: 110px;
              font-size: 2.4rem;
              font-weight: $font-bold;
              line-height: 1.3;
              text-align: center;
              @include media-maxW(767) {
                width: 80px;
                font-size: 1.8rem;
              }
            }
          }
        }
      }
      .list {
        @extend.flex;
        margin: 0 -40px;
        .item {
          @extend.flex;
          width: 50%;
          margin-bottom: 40px;
          padding: 0 40px;
          @include media-maxW(767) {
            width: 100%;
            margin-bottom: 0;
            padding: 20px 40px;
            &:nth-child(odd) {
              background-color: $cl-iron;
            }
          }
          .download {
            width: 100px;
            text-align: center;
            @include media-maxW(767) {
              order: 2;
              width: 50px;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: flex-end;
            }
            figure {
              width: 40px;
              margin: 0 auto 10px;
              @include media-maxW(767) {
                width: 30px;
                margin: 0;
              }
              img {
                width: 100%;
              }
            }
            span {
              font-size: 1.8rem;
              @include media-maxW(767) {display: none;}
            }
          }
          .description {
            width: calc(100% - 100px);
            font-size: 1.8rem;
            padding: 0 20px;
            border-left: 1px solid $cl-iron;
            @include media-maxW(767) {
              order: 1;
              width: calc(100% - 50px);
              font-size: 1.4rem;
              padding: 0;
              border-left: none;
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
            }
            .time {
              margin-bottom: 5px;
              @include media-maxW(767) {
                margin: 0;
                order: 2;
              }
              span {
                display: none;
                @include media-maxW(767) {
                  display: inline-block;
                  font-weight: $font-bold;
                }                
              }
            }
            .short-des {
              line-height: 1.5;
              @include media-maxW(767) {
                order: 1;
                font-weight: $font-bold;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}