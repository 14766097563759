.recruitment-filter {
	display: flex;
	justify-content: center;
	padding: 30px 0;

	@include media-maxW(768) {
		padding: 0;
	}

	&-container {
		width: 90%;

		@include media-maxW(768) {
			width: 100%;
		}
	}

	&__list {
		// display: flex;
		// flex-wrap: wrap;
		// justify-content: space-between;    
		display: grid;
		grid-template-columns: calc(33.33% - 20px) calc(33.33% - 20px) calc(33.33% - 20px);
    grid-gap: 30px;


		@include media-maxW(768) {
			display: none;
		}
	}
	
	&__select {
		display: none;
		margin: 0;

		@include media-maxW(768) {
			display: block;
		}

		&.controls {
			margin: 0;

			.sort {
				width: 100%;
				border: none;
				height: 60px;

				.place-holder {
					color: $cl-gray;
					padding-left: 24px;
				}

				.current {
					padding-left: 30px;
					text-transform: uppercase;
					font-size: 20px;
					font-weight: 700;			
				}

				.list {
					top: 60px;
					background: $cl-white;
					border: none;
					overflow-y: auto;

					.option {
						width: auto !important;
						text-align: left;
						font-size: 16px;
						color: $cl-black;
						font-weight: 100;
						padding: 0px 40px;

						&.active {
							background-color: $cl-black;
							
							div {
								border: none;
								color: $cl-white;
							}
						}

						div {
							padding: 12px 20px;
    					border-bottom: solid 1px #E7E7EA;
						}
					}
				}
			}
		}
	}

	&__item {
		position: relative;
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid $cl-shady-lady;
		padding: 15px 25px 15px 0;
		font-size: 2.8rem;
		font-weight: 300;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: calc(50% - 2px);
			right: 0;
			width: 15px;
			height: 3px;
			background: $cl-black;
			border-radius: 5px;
			transform: rotate(55deg);
		}

		&::after {
			top: calc(50% + 8px);
			transform: rotate(-55deg);
		}

		&__name {
			padding-bottom: 10px;
			font-weight: 600;
			line-height: 1.2;
			font-size: 20px;
		}

		&__jobs-amount {
			font-size: 18px;
		}
	}
}

.recruitment {
	padding: 30px 0 0;

	@include media-maxW(768) {
		padding: 0;
		.container {
			padding: 0;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__item {
		align-self: flex-start;
		display: flex;
		flex-direction: column;
		width: calc((100% - 60px) / 2);
		padding-bottom: 60px;

		@include media-maxW(768) {
			width: 100%;
			text-align: center;
			padding: 40px 16px;

			&:nth-child(odd) {
				background: $cl-iron;
			}

			.recruitment__item__quantity, .recruitment__item__date, .recruitment__item__buttons {
				display: none;
			}

			.recruitment__item__name {
				font-size: 20px;
			}

			.recruitment__item__location {
				font-size: 14px;
				font-weight: $font-light;
			}
		}

		&__name {
			padding-bottom: 15px;
			font-size: 20px;
			font-weight: bold;
		}

		&__info {
			padding: 5px 0;
			font-size: 18px;
			line-height: 1.2;
			font-weight: 100;
		}

		&__buttons {
			display: flex;
			justify-content: flex-start;
			padding-top: 15px;
		}

		&__button {
			position: relative;
			padding: 12px 24px;
			background: $cl-black;
			color: white;
			font-size: 16px;
			font-weight: 100;
			-webkit-box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
			box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
			text-shadow: none;
			-webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
			transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

			&:after {
				content: '';
				width: 100%;
				height: 100%;
				-webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
				transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
				-webkit-transform-origin: center center;
				transform-origin: center center;
				z-index: -1;
				border: 1px solid #000;
				position: absolute;
				top: 0;
				left: 0;
			}

			&:hover{
				color: #000;
				background-color: #fff;
				// border: solid 1px #000;
				outline: 1px solid #000;
				text-shadow: 1px 1px 2px #858484;

				&:after {
					opacity: 0;
					-webkit-transform: scale(1.2, 1.6);
					transform: scale(1.2, 1.6);
				}
			}
		}
	}
}