/**
 * Main CSS
 */

// General imports ------------------------------------------------------------
//
// These imports don't generate CSS, but make defined variables, functions,
// and mixins available for further usage
/* Locale Setting */
$locale: 'en';

/** Base */
@import 'base/mixin';
@import 'base/config';
@import 'base/functions';
@import 'base/reset';
@import 'base/keyframe';

// Styles ---------------------------------------------------------------------
@import 'module/form';
@import 'module/misc';
@import 'module/layout';
@import 'module/header';
@import 'module/header-mb';
@import 'module/footer';
@import 'module/stickyElement';
@import 'module/subscribe';
@import 'page/home';
@import 'page/base';
@import 'page/news';
@import 'page/news-detail';
@import 'page/report';
@import 'page/history';
@import 'page/bod';
@import 'page/partner';
@import 'page/vision';
@import 'page/organization';
@import 'page/community';
@import 'page/detail-community';
@import 'page/recruitment';
@import 'page/detail-recruitment';
@import 'page/project';
@import 'page/detail-project';
@import 'page/contact';
@import 'page/prize';
@import 'page/error';
@import 'page/regular-report';
@import 'page/policy';
@import 'page/detail-policy';
@import 'page/rule';

// Browser & Mobile fix -------------------------------------------------------
@import 'fix';

// Print ----------------------------------------------------------------------
@import 'module/print';
