// Library imports ------------------------------------------------------------
//
// Links to common libraries are included below. Place the files at the root
// of the sass folder

// Global vars ----------------------------------------------------------------
$base-font-size: 18px;
$base-line-height: 1.5;

// Colors ---------------------------------------------------------------------
$cl-white: #fff;
$cl-black: #000;
$cl-red: #fb3344;
$cl-orange: #e16734;
$cl-green: #98bd45;
$cl-yellow: #f1b13b;
$cl-brown: #60462c;
$cl-blue: #0000fd;
$cl-gray: #858484;

$cl-mercury: #e7e7e7;
$cl-dove-gray: #666666;
$cl-disco: #811559;
$cl-black-marlin: #3f2e1d;
$cl-landing-banner: #ffe200;
$cl-landing-content: #d08b2a;
$cl-landing-btn: #e87439;
$cl-border-gray: #c19342;
$cl-iron: #e7e7e9;
$cl-mojo: #c23d48;
$cl-boulder: #747474;
$cl-tapa: #747372;
$cl-shady-lady: #acabac;
$cl-mine-shaft: #383838;
$cl-cardinal: #bb2128;
$cl-bay-of-many: #2e3192;
$cl-mountain-mist: #a8a2ab;
$cl-silver: #bebebe;
$cl-melrose: #a9a9fc;
$cl-tundora: #404040;
$cl-seashell: #f1f1f1;
$cl-dustygray: #999999;
$cl-concrete: #f2f2f2;
$cl-atoll: #0c688a;
$cl-orange-peel: #f59c00;
$cl-light-grey: #d3d3d3;

$base-bg-color: #fff;
$base-font-color: #000;
$border-color: #ccc;
$page-bg-color: #f6f6f6;

$z-level: (
  'modal':    9999,
  'overlay':  8000,
  'dropdown': 7000,
  'header':   5000,
  'footer':   5000,
  'default':  1,
  'below':    -1
);

// Font ----------------------------------------------------------------

@font-face {
  font-family: 'Open Sans';
  src: url('../font/OpenSans/OpenSans-Bold.eot');
  src: url('../font/OpenSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('../font/OpenSans/OpenSans-Bold.woff2') format('woff2'),
      url('../font/OpenSans/OpenSans-Bold.woff') format('woff'),
      url('../font/OpenSans/OpenSans-Bold.ttf') format('truetype'),
      url('../font/OpenSans/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../font/OpenSans/OpenSans-Regular.eot');
  src: url('../font/OpenSans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('../font/OpenSans/OpenSans-Regular.woff2') format('woff2'),
      url('../font/OpenSans/OpenSans-Regular.woff') format('woff'),
      url('../font/OpenSans/OpenSans-Regular.ttf') format('truetype'),
      url('../font/OpenSans/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../font/OpenSans/OpenSans-Light.eot');
  src: url('../font/OpenSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
      url('../font/OpenSans/OpenSans-Light.woff2') format('woff2'),
      url('../font/OpenSans/OpenSans-Light.woff') format('woff'),
      url('../font/OpenSans/OpenSans-Light.ttf') format('truetype'),
      url('../font/OpenSans/OpenSans-Light.svg#OpenSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

$em-base: 16px;
$font-name: 'Open Sans', 'Helvetica Neue', Arial, sans-serif !default;
$font-light: 300;
$font-regular: normal;
$font-semibold: bold;
$font-bold: bold;

// Layout & Positioning Properties (position, float, clear, display)
// Display & Box Model Properties (width, height, margin, padding)
// Visual Properties (color, background, border, box-shadow)
// Typography Properties (font-size, font-family, text-align, text-transform)
// Animation Properties (transition, animation)
// Misc Properties (cursor, overflow, z-index)