.fixed-0-0 {
  position: fixed;
  top: 0;
  left: 0;
}

.abs-0-0 {
  position: absolute;
  top: 0;
  left: 0;
}

.abs-50-50 {
  position: absolute;
  top: 50%;
  left: 50%;
}

.full-scr {
  position: relative;
  width: 100vw;
  height: calc(100vh - 130px);
  @include media-maxW(960) {height: calc(100vh - 80px);}
}

.base-overlay {
  @extend.fixed-0-0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .75);
}

.block-full {
  display: block;
  width: 100%;
  height: 100%;
}

.js-show {display: block;}

.js-hide,
.hidden { 
  display: none; 
}

.align-left {text-align: left;}
.align-right {text-align: right;}
.align-justify {text-align: justify;}
.align-center {text-align-last: center;}

.center-x,
.center-y,
.center-xy {
  position: absolute;
}

.center-x { 
  left: 50%;
  transform: translateX(-50%);
}

.center-y {
  top: 50%;
  transform: translateY(-50%);
}

.center-xy {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-img {
  @extend.center-xy;
  min-width: 100%;
  min-height: 100%;
}

.arrow-down {
  position: absolute;
  &:before,
  &:after {
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
  }

  &:before {
    left: 0;
    transform: rotate(30deg);
  }

  &:after {
    right: 0;
    transform: rotate(-30deg);
  }
}

.base-btn {
  position: relative;
  padding: 15px 65px;
  color: $cl-white;
  cursor: pointer;
  @include media-maxW(960) {
    padding: 10px 45px;
  }
}

.btn {
  @extend.base-btn;
  display: inline-block;
  font-size: 2rem;
  box-shadow: inset 0 0 20px rgba($cl-white, 0);
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  @include media-maxW(960) { font-size: 1.4rem; }
  
  &:after {
    @extend.abs-0-0;
    content: '';
    width: 100%;
    height: 100%;
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    transform-origin: center center;
    z-index: -1;
  }

  @include hover {
    text-shadow: 1px 1px 2px $cl-gray;
    &:after {
      opacity: 0;
      transform: scale(1.2, 1.6);
    }
  }
}

.gradient {
  position: absolute;
  bottom: 0;
  left: 0;
}

.img-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.icon-grid {
  width: 20px;
  height: 20px;
  background-image: url("asset/icon/svg/icon-grid.svg");
}

.nice-select-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: $cl-black;
  opacity: 0.3;
  z-index: 1;
}