#page-history {  
  .history {
    margin: 40px 0;
    @include media-maxW(480) {margin: 20px 0};
    .history-wrap {
      position: relative;
      width: 100%;
      overflow: hidden;
      margin-top: 50px;
      @include media-maxW(767) {
        max-width: 640px;
        margin: 4rem auto 0;
        padding-top: 5rem;
      }
      @include media-maxW(479) {
        margin: 2rem auto 0;
        padding-top: 3.5rem;
      }
      .history-line {
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: $cl-cardinal;
        left: 50%;
        transform: translateX(-50%);
      }
      .history-item {
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 8.5rem;
        @include media-maxW(1200) {
          margin-bottom: 6.5rem;
        }
        @include media-maxW(991) {
          margin-bottom: 3rem;
        }
        @include media-maxW(767) {
          max-width: 640px;
          margin: 0 auto 7rem;
          border-radius: 2rem;
          border: 1px solid $cl-cardinal;
          background-color: $cl-white;
          padding: 6rem 3rem 3rem;
          transition: all .3s ease-in-out;
        }
        @include media-maxW(479) {
          margin-bottom: 4.5rem;
          padding: 4rem 1.5rem 1.5rem;
          border-radius: 1rem;
        }
        .covert {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          background-color: $cl-white;
          z-index: 1;
          @include media-maxW(767) {
            display: none;
          }
        }
        &:nth-last-child(2) {
          margin-bottom: 0;
        }
        .copy {
          flex: 1 1 50%;
          padding-right: 5rem;
          text-align: center;
          z-index: 1;
          position: relative;
          @include media-maxW(767) {
            flex: 1 1 100%;
            padding: 0;
            order: 2;
            position: static;
          }
          .year {
            font-weight: $font-bold;
            font-size: 3rem;
            width: 120px;
            height: 108px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            margin-bottom: -54px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            z-index: 2;
            @include media-maxW(767) {
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              margin-top: -4.5rem;
              width: 10.5rem;
              height: 9rem;
            }
            @include media-maxW(479) {
              margin-top: -3.25rem;
              width: 7.25rem;
              height: 6.5rem;
              font-size: 1.8rem;
            }
          }
          .text {
            border-radius: 3rem;
            border: 1px solid $cl-cardinal;
            padding: 65px 20px 20px;
            font-weight: $font-light;
            font-size: 1.6rem;
            line-height: 1.6;
            text-align: justify;
            position: relative;
            strong {
              font-weight: $font-bold;
            }
            @include media-maxW(1200) {
              padding: 6rem 3rem 3rem;
            }
            @include media-maxW(991) {
              padding: 5rem 2rem 2rem;
            }
            @include media-maxW(767) {
              border-radius: 0;
              border: none;
              padding: 0;
              transition: all .3s ease-in-out 0s;
              max-height: 10rem;
              overflow: hidden;
            }
            &::before {
              content: '';
              position: absolute;
              width: 5rem;
              height: 1px;
              top: 50%;
              right: -50px;
              transform: translateY(-50%);
              background-color: $cl-cardinal;
              @include media-maxW(767) {
                display: none;
              }
            }
            &::after {
              content: '';
              position: absolute;
              width: 1rem;
              height: 1rem;
              border-radius: 100%;
              top: 50%;
              right: -55px;
              transform: translateY(-50%);
              background-color: $cl-cardinal;
              @include media-maxW(767) {
                display: none;
              }
            }
          }
          .view-more {
            display: none;
            padding: 1.5rem 0 0;
            @include media-maxW(767) {
              display: inline-block;
            }
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              color: $cl-black;
              transition: all .3s ease-in-out 0s;
              span {
                margin-right: 5px;
              }
              &.close-view {
                display: none;
              }
            }
          }
          &.js-active {
            .text {
              max-height: 100%;
            }
            .view-more {
              .show-view {
                display: none;
              }
              .close-view {
                display: flex;
              }
            }
          }
        }
        .img {
          position: relative;
          flex: 1 1 50%;
          padding-left: 5rem;
          z-index: 2;
          @include media-maxW(767) {
            order: 1;
            padding: 0;
            margin-bottom: 2rem;
          }
          .slider-img {
            width: 100%;
            // transform: translateY(-50%);
            .list-img {
              width: 100%;
              height: 250px;
              position: relative;
              background-color: $cl-white;
              figure {
                position: absolute;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                opacity: 0;
                &:first-child {
                  opacity: 1;
                }
              }
              video {
                width: 100%;
                height: 100%;
              }
            }
            .dots {
              padding: 1rem 0;
              display: flex;
              align-items: center;
              justify-content: center;
              .dot {
                width: 1rem;
                height: 1rem;
                border-radius: 100%;
                background-color: #7f7f7f;
                border: 1px solid transparent;
                margin: 0 0.7rem;
                transition: .3s ease-in-out 0s;
                cursor: pointer;
                &.dot-active {
                  background-color: $cl-cardinal;
                }
              }
              @include media-maxW(767) {
                margin-top: -4rem;
                padding: 1.5rem 0;
                position: relative;
                z-index: 2;
                .dot {
                  border-color: $cl-white;
                }
              }
            }
          }
        }
        &.img-left {
          .copy {
            order: 2;
            padding: 0 0 0 5rem;
            @include media-maxW(767) {padding: 0;}
            .text {
              &::before {
                right: inherit;
                left: -50px;
              }
              &::after {
                right: inherit;
                left: -55px;
              }
            }
          }
          .img {
            padding: 0 5rem 0 0;
            @include media-maxW(767) {padding: 0;}
          }
        }
      }
    }
    
    .load-more {
      text-align: center;
      padding-top: 6rem;
      @include media-maxW(767) {padding-top: 0;}
      a {
        position: relative;
        display: inline-block;
        font-weight: $font-bold;
        font-size: 2.5rem;
        color: $cl-gray;
        padding: 2rem 6.5rem;
        background: $cl-white url('../img/history/bg-loadmore.png') no-repeat center;
        background-size: contain;
        z-index: 2;
        transition: .3s ease-in-out 0s;
        @include media-maxW(767) {font-size: 1.4rem;}
        &:hover {
          background-image: url('../img/history/bg-loadmore-hv.png');
          color: $cl-cardinal;
        }
      }
    }
  }
}