#page-news,
#page-news-detail,
#page-report,
#page-community,
#page-detail-community {
  .w-100 {
    width: 100%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    [class*="col-"] {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .col-100 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .col-50 {
      flex: 0 0 50%;
      max-width: 50%;

      @media screen and (max-width: 600px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .col-33 {
      flex: 0 0 33.33%;
      max-width: 33.33%;

      @media screen and (max-width: 600px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  .block-news-item {
    margin-bottom: 3rem;

    .thumbnail {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .block-content {
      background-color: #e7e7e9;
      color: $cl-black;
      font-size: 2rem;
      @include media-maxW(960) {
        font-size: 1.6rem;
      }
      .category-name,
      time {
        font-weight: $font-light;
        line-height: 1.25;
      }
      time {
        display: block;
        margin-bottom: 16px;
      }
      h4 {
        font-weight: $font-bold;
        line-height: 1.5;
      }
    }
  }

  .block-news-item.horizontal {
    @media screen and (min-width: 600px) and (max-width: 1200px) {
      display: grid;
      grid-template-columns: 45% 55%;
    }

    @media screen and (min-width: 1200px) {
      display: grid;
      grid-template-columns: 40% 60%;
    }

    .thumbnail {
      padding-top: 70%;

      @media screen and (min-width: 600px) {
        order: 2;
        padding-top: 60%;
      }
    }

    .block-content {
      padding: 2.5rem;

      @media screen and (min-width: 600px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      @media screen and (min-width: 1200px) {
        padding: 5rem;
      }
    }
  }

  .block-news-item.vertical {
    .thumbnail {
      padding-top: 70%;
    }

    .block-content {
      padding: 2.5rem;

      @media screen and (min-width: 1200px) {
        padding: 2.5rem 6.5rem;
      }

      h4 {
        font-size: 1.8rem;

        @media screen and (min-width: 600px) {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }

  .block-sort {
    position: relative;

    @media screen and (max-width: 600px) {
      width: calc(100% - 6rem);
    }

    &::before {
      content: "\25BE";
      position: absolute;
      top: 45%;
      right: 2.5rem;
      transform: translate(50%, -50%);
      font-size: 2rem;
    }

    select {
      appearance: none;
      width: 100%;
      height: 4rem;
      background-color: transparent;
      font-size: 1.6rem;
      border: 1px solid #000000;
      border-radius: unset;
      padding-left: 2rem;
      padding-right: 5rem;

      &:focus {
        outline: unset;
      }
    }
  }

  .block-search {
    display: inline-block;
    position: relative;

    button {
      color: unset;
      background-color: unset;
      border: unset;
      padding: unset;
      cursor: pointer;

      &:focus {
        outline: 0;
      }

      img {
        position: relative;
        width: 4rem;
        background-color: #ffffff;
        border-radius: 50%;
        z-index: 20;
      }
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 4rem;
      height: 4rem;
      font-size: 1.6rem;
      border: 1px solid #000000;
      border-radius: 2rem;
      padding-left: 0rem;
      transition: all 0.25s ease-in;
      z-index: 10;

      @media screen and (max-width: 600px) {
        left: unset;
        right: 0;
      }
    }

    &:focus-within {
      input {
        width: 30rem;
        padding-left: 4.5rem;

        @media screen and (max-width: 600px) {
          width: calc(100vw - 3rem);
          border-top-left-radius: unset;
          border-bottom-left-radius: unset;
          padding-left: 2rem;
          padding-right: 4.5rem;
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding-left: 0;
    .page-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover,
      &.active {
        .page-link {
          color: $cl-black;
          font-weight: $font-bold;
        }
      }
      .page-link {
        color: rgba(0, 0, 0, 0.4);
        font-size: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}
