@mixin fontface($name, $weight: normal, $style: normal){
  @font-face {
    font-family: "#{$name}";
    src: url('../font/#{$name}/#{$name}.eot');
    src: url('../font/#{$name}/#{$name}.eot?#iefix') format('embedded-opentype'),
         url('../font/#{$name}/#{$name}.woff') format('woff'),
         url('../font/#{$name}/#{$name}.ttf') format('truetype'),
         url('../font/#{$name}/#{$name}.svg?#webfont') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin placeholderColor($color) {
  &::-webkit-input-placeholder { 
    /* Chrome/Opera/Safari */
    color: $color;
  }
  &::-moz-placeholder { 
    /* Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder { 
    /* IE 10+ */
    color: $color;
  }
  &:-moz-placeholder { 
    /* Firefox 18- */
    color: $color;
  }
}

@mixin bg-gradient($startColor, $endColor) {
  background: $startColor; /* Old Browsers */
  background: -moz-radial-gradient(center, ellipse cover, $startColor 0%, $endColor 100%); /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $startColor), color-stop(100%, $endColor));/* Chrome, Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover, $startColor 0%, $endColor 100%); /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(center, ellipse cover, $startColor 0%, $endColor 100%); /* Opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, $startColor 0%, $endColor 100%); /* IE 10+ */
  background: radial-gradient(ellipse at center, $startColor 0%, $endColor 100%);/* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$startColor', endColorstr='$endColor', GradientType=1 );/* IE6-9 fallback on horizontal gradient */
}

@mixin arrow-y($wrap-size, $line-size, $thickness, $color: $cl-white) {
  width: $wrap-size;
  height: $thickness;

  &:before,
  &:after {
    width: $line-size;
    background: $color;
  }
}
@mixin arrow-x($wrap-size, $line-size, $thickness, $color: $cl-white) {
  width: $thickness;
  height: $wrap-size;

  &:before,
  &:after {
    height: $line-size;
    background: $color;
  }
}

@mixin hover {
	.can-hover &:hover { @content; }
}

@mixin media-maxW($value) {
	@media only screen and (max-width:$value + 'px') {
    @content;
  }
}

@mixin media-minW($value) {
	@media only screen and (min-width:$value + 'px') {
    @content;
  }
}

@mixin media-maxH($value) {
	@media only screen and (max-height:$value + 'px') {
    @content;
  }
}

@mixin gradient($width, $height, $direction) {
  width: $width;
  height: $height;
  background: linear-gradient(to $direction, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%);
}
