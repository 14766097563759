#page-vision {
  .vision,
  .mission,
  .value,
  .philosophy {
    max-width: 1100px;
    margin: 100px auto;
    @include media-maxW(1200) {margin: 60px 0};
    @include media-maxW(480) {margin: 40px 0};
    .container {
      .content {
        padding-bottom: 20px;
        p {
          width: 80%;
          margin: 0 auto;
          text-align: justify;
          text-align-last: center;
          line-height: 1.5;
          font-size: 2.2rem;
          @include media-maxW(960) {font-size: 2rem};
          @include media-maxW(480) {font-size: 1.6rem};
        }
        .value-item {
          width: 50%;
          margin: 20px 0;
          .wrap {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
            figure {
              width: 100%;
              max-width: 150px;
              margin: 0 auto 20px;
              @include media-maxW(480) {max-width: 100px};
              img {
                width: 100%;
                height: 100%;
              }
            }
            figcaption {
              h5 {
                font-size: 2.4rem;
                font-weight: $font-bold;
                text-align: center;
                margin-bottom: 10px;
                @include media-maxW(960) {font-size: 2.2rem};
                @include media-maxW(480) {font-size: 1.8rem};
              }
              p {
                font-size: 2.2rem;
                text-align: center;
                @include media-maxW(960) {font-size: 2rem};
                @include media-maxW(480) {font-size: 1.6rem};
              }
            }
          }
        }
      }
    }
  }
}