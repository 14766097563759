.main-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 50px 0;
  background-color: $cl-iron;
  font-weight: $font-light;
  font-size: 1rem;
  color: $cl-black;
  
  @include media-maxW(960) {
    padding: 30px 0;
    font-size: 1.4rem;
  }

  &-logo {
    @include media-maxW(1024) {
      display: none;
    }
  }

  .footer-wrap {
    width: 100%;
    max-width: 1330px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    @include media-maxW(1370) {
      padding: 0 20px;
    }

    @include media-maxW(768) {
      display: block;
    }

    & > * {
      width: 25%;
      padding: 0 20px;
      @include media-maxW(1280) {
        width: 28.33%;
      }
      @include media-maxW(1024) {
        width: 33.33%;
        padding: 0 20px 0 0;
      }
      @include media-maxW(768) {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
      }
      &:first-child { margin-right: 20px; }
      &:first-child,
      &:last-child { 
        padding: 0; 
        @include media-maxW(768) { margin-bottom: 0; }
      }
    }
  }

  figure {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    justify-content: space-between;
    min-width: 290px;

    @include media-maxW(1024) {
      display: none;
    }

    @include media-maxW(768) {
      display: flex;
    }

    img {
      width: 80%;
      max-width: 190px;
      margin-bottom: 15px;
    }

    .lang {
      @include media-maxW(1024) {
        display: none;
      }

      @include media-maxW(768) {
        display: block;
        margin-bottom: 30px;
      }

      li::after {
        font-size: 1.4rem;

        @include media-maxW(768) {
          font-size: 1.8rem;
        }
      }

      a {
        font-size: 1.4rem;
        
        @include media-maxW(768) {
          font-size: 1.8rem;
        }
      }
    }
  }

  section {
    max-width: 250px;

    @include media-maxW(1024) { 
      max-width: unset;  
    }
  }

  form {
    .feedback,
    .warning {
      margin-bottom: 12px;
      display: none;
    }

    .warning { color: $cl-cardinal; }
  }

  .subscribe-form {
    .form-field {
      border: none;

      input {
        border-bottom: 1px solid #707070;
        font-size: 15px;
      }

      .feedback, .warning {
        margin-top: 4px;
        display: none;
        font-size: 12px;
      }
    }
  }

  &-title {
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: $font-bold;
    line-height: 1.25;
    text-align: left;
    text-transform: uppercase;

    @include media-maxW(960) { 
      margin-bottom: 10px;
      font-size: 1.6rem; 
    }
  }

  p, a, input {
    font-size: 15px;
  }

  p {
    line-height: 1.5;
    margin-bottom: 5px;
    @include media-maxW(768) {
      line-height: 1.4;
      margin-bottom: 0;
    }
    &:last-child { margin-bottom: 0; }
  }

  .btn-down {
    padding: 10px 20px;
    width: 100%;
    background: $cl-shady-lady;
    text-align: center;
    text-transform: uppercase;
    color: $cl-black;
    font-weight: $font-semibold;
    display: none;
    @include media-maxW(768) { display: block; }

    span {
      width: 15px;
      height: 15px;;
      background: url(../img/bg/icon-download.png) no-repeat;
      background-size: cover;
      display: inline-block;
      margin-right: 10px;
    }
  }

  .tri-link {
    @extend.underlink;
    display: inline-block;
    color: $cl-black;
    @include media-maxW(768) { display: none; }

    &:after { background-color: $cl-black; }
    &:before {
      @extend.center-y;
      right: -22px;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 8px;
      border-color: transparent transparent transparent $cl-cardinal;
    }
  }

  .btn-totop { display: none; }

  .lang {
    li {
      h5 {
        font-size: 16px;
      }
    }
  }
}

