.header-mb {
  @extend.abs-0-0;
  width: 100%;
  min-height: 100vh;
  padding: 100px 20px 50px;
  background-color: $cl-white;
  transform: translateX(-100%);
  box-shadow: 0px 0px 10px 2px rgba($cl-black, 0.25);
  z-index: 3;
  display: none;

  .mb-menu {
    & > li {
      display: block;
      min-height: 36px;

      & > a {
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: 700;
        &.js-active { 
          color: $cl-cardinal; 
        }
      }
    }

    a {
      color: $cl-black;
      line-height: 18px; 
    }
  }

  .sub-menu {
    margin: 15px 0 20px;
    display: none;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background-color: $cl-iron;
      position: absolute;
      top: 0;
      left: 2px;
    }
    li {
      margin-bottom: 10px;
      &:last-child { margin-bottom: 0; }
    }
    a { 
      padding-left: 20px;
      font-size: 1.4rem;
      position: relative;
      display: inline-block;
      &.js-active {
        font-weight: $font-semibold;
        &:before { opacity: 1; }
      }

      &:before {
        @extend.abs-0-0;
        content: '';
        width: 5px;
        height: 100%;
        background-color: $cl-cardinal;
        opacity: 0;
      }
    }
  }

  .bottom-wrap {
    position: relative;
    display: flex;
    width: 100%;
  }

  .social {
    width: 100%;
    padding-right: 90px;
    li {
      width: 20px;
      height: 20px;
      float: left;
      margin-right: 10px;
      &:last-child { margin-right: 0; }
    }

    img {
      width: 100%;
    }
  }

  .lang {
    @extend.center-y;
    right: 0;
    width: 90px;
    a { font-size: 1.6rem; }
  }

  

}