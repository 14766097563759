.form {
  &-pair {
    display: flex;

    @include media-maxW(768) { 
      flex-direction: column;
    }
  }

  &-field {
    position: relative;
    flex-grow: 1;
    margin: 5px;
    border-bottom: 1px solid #707070;

    @include media-maxW(768) { 
      border: 0;
      margin: 5px 0;
    }

    input[type="text"], input[type="number"], input[type="email"] {
      width: 100%;
      background: none;
      margin: 0;
      padding: 10px 5px;
      font-size: 12px;
      font-weight: 300;
      @include placeholderColor($cl-shady-lady);

      @include media-maxW(768) {
        padding: 10px 15px;
        background: $cl-shady-lady;
        color: $cl-black;
        font-size: 1.4rem;

        @include placeholderColor($cl-black);
      }

      &::-webkit-input-placeholder {
        color: $cl-shady-lady;
        font-family: $font-name;
        font-weight: 300;

        @include media-maxW(768) { 
          color: $cl-black;
        }
      }
    }
  }
}