#page-detail-recruitment {
	.detail-recruitment {
		margin: 40px 0 60px;
    @include media-maxW(480) {margin: 20px 0};
    .container {
	    .detail-job {
	    	margin: 60px 0;
	    	text-align: justify;
	    	@include media-maxW(768) {margin: 40px 0;}
	    	.item {
	    		margin-bottom: 60px;
		    	.head-item {
		    		font-size: 2.4rem;
		    		margin-bottom: 10px;
		    		@include media-maxW(768) {
		    			font-size: 2rem;
							text-align: center;	
							font-weight: $font-bold;
		    		}
		    	}
		    	.desc-item {
		    		font-size: 1.8rem;
		    		font-weight: $font-light;
		    		@include media-maxW(768) {font-size: 1.4rem;}
		    	}
		    	p {
		    		margin-bottom: 10px;
		    		line-height: 1.8;
					}
					strong {font-weight: $font-bold;}
			  	em {font-style: italic;}
		    	ul {
				    list-style-type: square;
				    margin-left: 20px;
				    font-weight: $font-light;
				    li {line-height: 1.8;}
				  }
				  ol {
				    list-style-type: decimal;
				    margin-left: 20px;
				    font-weight: $font-light;
				    li {line-height: 1.8;}
				  }
	    	}
	    }
	    .updown-cv {
	    	display: flex;
	    	justify-content: center;
	    	@include media-maxW(767) {flex-direction: column;}
	    	button {
	    		@extend.btn;
          background: $cl-black;
          color: $cl-white;
          padding: 5px 20px;
          font-family: Open Sans;
          font-size: 1.8rem;
          font-weight: $font-light;
          border: none;
          box-sizing: border-box;
          @include media-maxW(767) {
          	width: 100%;
          	font-size: 1.4rem;
          	&:first-child {
          		margin: 0 0 10px 0;
          	}
          }
          &:after {border: 1px solid $cl-black;}
          &:hover,
          &:focus{
          	color: $cl-black;
          	background-color: $cl-white;
          	outline: 1px solid $cl-black;
          }
          &:first-child {
          	margin-right: 40px;
          }
	    	}
	    }
    }
	}
}