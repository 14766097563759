.main-header {
  display: block;
  box-shadow: 0px 0px 10px 2px rgba($cl-black, 0.25);
  background-color: $cl-white;
  position: relative;
  z-index: 4;

  .header-wrap {
    width: 100%;
    max-width: 1280px;
    height: 130px;
    margin: auto;
    position: relative;
    @include media-maxW(1024) {
      height: 80px;
    }
    
  }

  .menu-left,
  .menu-right {
    @extend.center-y;
    width: 510px;
    @include media-maxW(1300) {
      width: 36%;
    }
    @include media-maxW(1024) { display: none; }

    nav {
      display: block;

      &:first-child {
        @extend.clearfix;
        height: 40px;
        border-bottom: 0.5px solid $cl-black;
      }
    }

    li { float: left; }
  }

  .menu-left { 
    left: 0; 
    @include media-maxW(1300) {
      left: 40px;
    }
  }

  .menu-right { 
    right: 0; 
    @include media-maxW(1300) {
      right: 40px;
    }
  }

  .social {
    ul { float: right; }
    li {
      margin-right: 15px;
      &:last-child { margin-right: 0; }
    }

    a {
      display: block;
      width: 24px;
      height: 24px;
    }

    figure {
      width: 100%;
      height: 100%;
      position: relative;
      @include hover {
        .normal { opacity: 0; }
        .hover { opacity: 1; }
      }

      img {
        width: 100%;
        height: 100%;
        transition: 0.25s opacity;
      }

      .normal { opacity: 1; }
      .hover {
        @extend.abs-0-0;
        opacity: 0;
      }
    }

    figcaption { display: none; }
  }

  
  .main-menu {
    display: flex;
    & > li {
      width: 33.33%;
      float: left;
      text-align: center;
      position: relative;
      & > .active {
        color: $cl-cardinal;
        font-weight: $font-bold;
      }
      @include hover {
        & > a:before { height: 6px; }
        .sub-menu { 
          transform: scaleY(1); 
          opacity: 1;
        }
      }

      &.js-active {
        & > a:before { height: 6px; }
        .sub-menu { 
          transform: scaleY(1); 
          opacity: 1;
        }
      }

      &:after {
        content: '';
        @extend.center-y;
        right: 0;
        width: 1px;
        height: 25%;
        background-color: $cl-black;
      }
      
      &:last-child:after { display: none; }
    }

    & > li > a {
      position: relative;
      display: block;
      padding: 20px 0;
      font-size: 1.4rem;
      color: $cl-black;
      &:before {
        content: '';
        height: 0;
        width: 100%;
        position: absolute;
        top: -1px;
        left: 0;
        background-color: $cl-cardinal;
        transition: 0.25s height;
      }
    }
  }
  
  .sub-menu {
    position: absolute;
    top: 56px;
    left: 50%;
    width: 210px;
    margin-left: -105px;
    background-color: rgba($cl-black, 0.9);
    padding: 15px 20px;
    border-radius: 10px;
    transform: scaleY(0);
    transform-origin: top center;
    opacity: 0;
    transition: all 0.35s ease-in-out;

    li {
      display: block;
      width: 100%;
      margin-bottom: 15px;
      text-align: center;
      &:last-child { margin-bottom: 0; }
    }

    a {
      color: $cl-tapa;
      font-size: 1.4rem;
      line-height: 1.25;
      &.active { color: $cl-white; }
      @include hover { color: $cl-white; }
    }
  }
    
  

  .logo {
    @extend.abs-50-50;
    transform: translate(-50%, -50%);
    max-width: 180px;
    @include media-maxW(1024) {
      height: 60px;
      left: 20px;
      transform: translate(0,-50%);
    }
    img {
      width: 100%;
      height: 100%;
      @include media-maxW(1024) {width: unset;}
    }
  }

  .btn-mb {
    @extend.center-y;
    right: 20px;
    width: 30px;
    display: none;
    @include media-maxW(1024) { display: block; }

    &.js-active {
      span {
        width: 115%;
        &:nth-of-type(1) { transform: rotate(45deg) translate(5px, 9px); }
        &:nth-of-type(2) { transform: rotate(-45deg) translate(-2px, -1px); }
        &:nth-of-type(3) { opacity: 0; }
      }
      p {
        &:nth-of-type(1) { display: none; }
        &:nth-of-type(2) { display: block; }
      }
    }

    span {
      display: block;
      height: 3px;
      width: 100%;
      background-color: $cl-black;
      margin-bottom: 6px;
      transition: 0.25s all;
      transform-origin: center center;
      &:nth-of-type(3) { width: 50%; }
    }

    p { 
      font-size: 1rem; 
      font-weight: $font-semibold;
      color: $cl-black;
      text-align: center;
      &:nth-of-type(2) { display: none; }
    }
  }
}


.lang {
  font-weight: $font-bold;
  li {
    &:after {
      content: '/';
      color: $cl-black;
      margin: 0 10px;

      float: left;
    }

    &:last-child:after { display: none; }
  }

  a {
    float: left;
    font-size: 1.8rem;
    color: $cl-gray;
    @include hover { color: $cl-black; }
    &.active { color: $cl-black; }
  }
}