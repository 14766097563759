#page-home {
  .achievement {
    margin-top: 100px;

    @include media-maxW(960) {
      margin-top: 50px;
    }

    .container {
      .achie-item {
        text-align: center;

        figure {
          height: 55px;

          @include media-maxW(960) {
            height: 40px;
          }

          img {
            height: 100%;
          }
        }

        figcaption {
          margin-top: 10px;

          .number {
            font-size: 5rem;
            font-weight: $font-bold;

            @include media-maxW(960) {
              font-size: 3.2rem;
            }
          }

          .caption {
            margin-top: 15px;
            font-size: 16px;
            font-weight: $font-light;
            line-height: 1.5;
          }
        }

        &:nth-child(1) {
          width: 20%;

          @include media-maxW(960) {
            width: 50%;
            padding-right: 5%;
          }
        }

        &:nth-child(2) {
          width: 35%;
          padding: 0 2.5% 0 5%;

          @include media-maxW(960) {
            width: 50%;
            padding: 0 0 0 5%;
          }
        }

        &:nth-child(3) {
          width: 25%;
          padding: 0 5% 0 2.5%;

          @include media-maxW(960) {
            width: 50%;
            margin-top: 20px;
            padding: 0 5% 0 0;
          }
        }

        &:nth-child(4) {
          width: 20%;

          @include media-maxW(960) {
            width: 50%;
            margin-top: 20px;
            padding: 0 0 0 5%;
          }
        }
      }
    }
  }

  .service {
    margin-top: 100px;

    @include media-maxW(960) {
      margin-top: 50px;
    }

    .container {
      .service-wrap {
        margin: 50px -2.5% 0 -2.5%;

        @include media-maxW(960) {
          margin: 25px -10px 0 -10px;
        }

        .service-item {
          width: 25%;
          padding: 0 2.5%;
          text-align: center;

          @include media-maxW(960) {
            width: 50%;
            padding: 0 10px;
          }

          &.equal-w {
            @include media-maxW(960) {
              flex: unset;
            }
          }

          figure {
            position: relative;
            width: 150px;
            height: 137px;
            margin: 0 auto;
            background: url("../img/home/service-background-1.jpg") center
              no-repeat;
            background-size: 100% 100%;

            @include media-maxW(960) {
              width: 100px;
              height: 91px;
            }

            &:after {
              content: "";
              display: block;
              padding-bottom: 100%;
            }

            &.hover {
              background: url("../img/home/service-background-2.jpg") center
                no-repeat;
              background-size: 100% 100%;
            }

            img {
              @extend.center-xy;
              width: 40%;
            }
          }

          figcaption {
            .name {
              margin-top: 30px;
              font-size: 2rem;
              font-weight: $font-light;

              @include media-maxW(960) {
                margin-top: 15px;
                font-size: 1.4rem;
              }
            }

            .mean {
              margin-top: 15px;
              font-size: 1.4rem;
              font-weight: $font-light;

              @include media-maxW(960) {
                margin-top: 15px;
                font-size: 1.2rem;
              }
            }

            .description {
              margin-top: 30px;
              color: $cl-gray;
              font-size: 1.2rem;
              font-weight: $font-light;
              line-height: 1.5;

              @include media-maxW(960) {
                margin-top: 15px;
                font-size: 1.4rem;
              }
            }
          }
        }

        .slick-dots {
          @extend.slick-dots;
        }
      }
    }
  }

  .project {
    margin-top: 100px;
    @include media-maxW(960) {
      margin-top: 50px;
    }

    @include media-maxW(768) {
      padding: 0 16px 40px 16px;
      background-color: #e7e7e9;
    }

    .project-header {
      padding: 25px 0;
      background-color: $cl-iron;
    }

    .project-slider {
      .slider-item {
        position: relative;

        figure {
          position: relative;
          width: 100%;
          overflow: hidden;
          height: 250px;

          img {
            @extend.center-img;
          }

          .gradient {
            width: 100%;
            height: 50%;
            background: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.7) 50%
            );
          }
        }

        figcaption {
          position: absolute;
          bottom: 120px;
          width: 100%;
          padding: 0 20px;
          color: $cl-white;
          font-size: 2.8rem;
          font-weight: $font-light;
          text-align: center;
          line-height: 1.5;

          @include media-maxW(960) {
            bottom: 90px;
            font-size: 1.8rem;
          }

          @include media-maxW(768) {
            bottom: 240px;
          }
        }

        .readmore {
          @extend.btn;
          position: absolute;
          bottom: 40px;
          left: 50%;
          transform: translateX(-50%);
          padding: 10px 20px;
          color: $cl-white;
          border: none;
          background: $cl-black;
          font-family: Open Sans;
          font-size: 16px;
          font-weight: $font-light;
          z-index: 0;

          &:after {
            border: 1px solid $cl-black;
          }

          @include media-maxW(960) {
            bottom: 40px;
          }
          
          @include media-maxW(768) {
            bottom: 190px;
          }
        }

        .logo-project {
          @extend.center-x;
          top: 0;
          width: 200px;
          max-width: 400px;
          right: 0;
          left: unset;
          transform: unset;
          margin: 16px 16px 0;

          @include media-maxW(768) {
            display: none;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        .btm-overview {
          display: flex;
          flex-direction: row;
          justify-content: center;
          order: 2;
          width: 100%;
          padding: 20px 10px;
          background-color: $cl-white;
          height: 150px;

          @include media-minW(769) {
            display: none;
          }

          .logo {
            width: 130px;
            height: auto;
            margin: 0; 
            text-align: center;
            display: flex;
            align-items: center;

            img {
              width: 100%;
            }
          }

          .info {
            width: calc(100% - 130px);
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-left: 15px;
            margin: 0;

            .name {
              font-size: 1.6rem;
              font-weight: $font-bold;
              line-height: 1.5;
              margin-bottom: 10px;
            }

            .position {
              font-size: 1.4rem;
              line-height: 1.5;
            }
          }
        }
      }

      .slick-prev,
      .slick-next {
        width: 70px;
        @extend.center-y;
      }

      .slick-prev {
        @extend.slick-prev;
        left: 5%;
      }

      .slick-next {
        @extend.slick-next;
        right: 5%;
      }

      .slick-dots {
        @extend.slick-dots;
        position: absolute;
        bottom: 10px;

        @include media-maxW(768) {
          bottom: 160px;
        }
      }
    }
  }

  .info {
    margin-top: 100px;

    @include media-maxW(960) {
      margin-top: 50px;
    }

    .container {
      .info-slider {
        margin: 50px -15px 0;

        @include media-maxW(960) {
          margin: 25px -5px 0;
        }

        .slider-item {
          padding: 0 15px;

          @include media-maxW(960) {
            padding: 0 5px;
          }

          figure {
            width: 100%;
            height: 250px;

            img {
              @extend.img-fit;
            }
          }

          figcaption {
            padding: 15px 25px;
            background-color: $cl-iron;

            @include media-maxW(960) {
              padding: 15px;
            }

            .info-title {
              font-size: 1.7rem;
              font-weight: $font-light;

              @include media-maxW(960) {
                font-size: 1.2rem;
              }
            }

            .info-date {
              margin-top: 5px;
              color: $cl-gray;
              font-size: 1.7rem;
              font-weight: $font-light;

              @include media-maxW(960) {
                font-size: 1.2rem;
              }
            }

            .info-shortdes {
              margin-top: 10px;
              font-size: 1.8rem;
              font-weight: $font-bold;
              line-height: 1.5;

              @include media-maxW(960) {
                font-size: 1.5rem;
              }
            }
          }
        }

        .slick-dots {
          @extend.slick-dots;
        }
      }
    }
  }

  .partner {
    margin: 100px 0;

    @include media-maxW(960) {
      margin: 50px 0;
    }

    .container {
      .partner-slider {
        margin: 40px 0px 0 0;

        @include media-maxW(960) {
          margin: 20px 0 0 0;
        }

        .slider-item {
          height: 150px;
          position: relative;

          @include media-maxW(1300) {
            height: 120px;
          }

          @include media-maxW(960) {
            height: 80px;
          }

          @include media-maxW(480) {
            height: 50px;
          }

          img {
            @extend.center-xy;
            height: 50%;

            @include media-maxW(960) {
              height: 65%;
            }
          }

          .normal {
            opacity: 1;
          }

          .hover {
            opacity: 0;
          }

          &:hover {
            .normal {
              opacity: 0;
            }

            .hover {
              opacity: 1;
            }
          }
        }

        .slick-dots {
          @extend.slick-dots;
        }
      }
    }
  }
}
