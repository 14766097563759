#page-error {
	margin: 80px 0;
  @include media-maxW(480) {margin: 40px 0};
	.container {
		text-align: center;
		color: $cl-gray;
		p {
			font-size: 3rem;
			font-weight: $font-light;
		}
		.status-code {
			font-size: 20rem;
		}
	}
}
