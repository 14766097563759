#page-organization {
  .organization {
    margin: 40px 0;
    @include media-maxW(480) {margin: 20px 0};
    .container {
      figure {
        margin-top: 25px;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}