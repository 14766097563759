#page-news-detail {
  .detail-news {
    margin: 40px 0;
    .header-news {
      margin-bottom: 3rem;
      h2 {
        color: $cl-black;
        font-size: 4.6rem;
        font-weight: $font-bold;
        line-height: 1.2;
        margin-bottom: 3rem;
        @include media-maxW(960) {font-size: 3.5rem;}
      }
      time {
        width: 50%;
        font-size: 1.6rem;
        font-style: italic;
      }
      figure {
        width: 50%;
        text-align: right;
        @include media-maxW(960) {display: none}
        img {
          max-width: 100%;
        }
      }
    }
    .content-news {
      margin-bottom: 3rem;
      text-align: justify;
      font-weight: 300;
      img {
        width: 800px;
        height: 100%;
        margin: 0 calc(50% - 400px);
        @include media-maxW(960) {
          width: 80%;
          margin: 0 10%;
        }
        @include media-maxW(480) {
          width: 100%;
          margin: 0;
        }
      }
      p {
        color: $cl-black;
        font-size: 1.8rem;
        font-weight: $font-light;
        line-height: 1.8;
        margin-bottom: 10px;
        @include media-maxW(960) {
          font-size: 1.4rem;
          margin-bottom: 1.4rem;
        }
      }
      strong {font-weight: $font-bold;}
      em {font-style: italic;}
      ul {
        list-style-type: square;
        margin-left: 20px;
        li {line-height: 1.8;}
      }
      ol {
        list-style-type: decimal;
        margin-left: 20px;
        li {line-height: 1.9;}
      }
    }
    .footer-news {
      border-top: 0.1rem solid #000000;
      border-bottom: 0.1rem solid #000000;
      padding: 15px 0;
      @include media-maxW(960) {padding: 10px 0;}
      .flex {
        justify-content: space-between;
        .email,.phone {
          margin: 15px 20px 15px 0;
          @include media-maxW(960) {margin: 0;}
        }
        .author {
          margin: 15px 0;
          @include media-maxW(960) {
            margin: 0;
            line-height: 1.5;
          }
        }       
        .email,.phone,.author {
          font-weight: $font-light;
          @include media-maxW(960) {
            display: flex;
            align-items: center;
            width: 100%;
            margin: 10px 20px;
            text-align: left;
          }
          img {
            width: 18px;
            height: 15px;
            vertical-align: bottom; 
            margin-right: 10px; 
          }
        }
      }
    }
  }

  .shared-news {
    margin-bottom: 40px;
    .title {
      width: 100%;
      text-align: center;
      color: $cl-black;
      font-size: 1.8rem;
      font-weight: $font-light;
      line-height: 1.6;
      @include media-maxW(960) {
        font-size: 1.4rem;
      }
      figure {
        display: inline-block;
        height: 25px;
        margin-right: 10px; 
        @include media-maxW(960) {height: 20px;}     
        img {
          height: 100%;
        }
      }
    }
  }

  .more-news {
    margin-bottom: 40px;
    h3 {
      color: #000000;
      font-size: 2.4rem;
      margin-bottom: 4.5rem;
      @include media-maxW(960) {text-align: center;}
    }
    .flex {
      margin: 0 -15px;
      @include media-maxW(960) {margin: 0 -5px;}
      .item {
        width: 33.33%;
        padding: 0 15px;
        @include media-maxW(960) {padding: 0 5px;}
        @include media-maxW(767) {
          width: 100%;
          margin: 0 auto;
        }
        figure {
          width: 100%;
          height: 250px;
          img {
            @extend.img-fit;
          }
        }
        .title {
          background-color: #e7e7e9;
          color: $cl-black;
          font-size: 2rem;
          padding: 15px 25px;
          text-align: left;
          @include media-maxW(960) {padding: 15px;}
          .category-name, time {
            font-size: 1.7rem;
            font-weight: $font-light;
            line-height: 1;
            @include media-maxW(960) {font-size: 1.2rem;}
          }
          time {
            display: block;
            margin-top: 5px;
            color: $cl-gray;
          }
          h4 {
            font-size: 1.8rem;
            font-weight: $font-bold;
            line-height: 1.5;
            margin-top: 10px;
            @include media-maxW(960) {font-size: 1.5rem;}
          }
        }
      }
      .slick-dots {
        @extend.slick-dots;
      }
    }
    p {
      text-align: center;
      padding-top: 1.5rem;
      a {
        font-size: 1.8rem;
      }
    }
  }
}
