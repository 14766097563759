#page-contact {
	.contact {
	 	margin: 40px 0;
	  @include media-maxW(480) {margin: 20px 0};
		.container {
	    form {
	    	text-align: center;
	    	margin: 40px 0;
	    	.item {
	    		margin-bottom: 20px;
	    		font-size: 1.8rem;
	    		@include media-maxW(768) {font-size: 1.4rem;}
		    	input {
		    		width: 100%;
		    		border: 1px solid $cl-black;
						padding: 10px 20px;
						font-size: 1.8rem;
						font-weight: $font-light;
						color: $cl-gray;
						@include media-maxW(767) {
							font-size: 1.4rem;
						}
		    	}
		    	textarea {
		    		width: 100%;
		    		height: 150px;
		    		border: 1px solid $cl-black;
		    		resize: none;
						padding: 10px 20px;
						font-size: 1.8rem;
						font-weight: $font-light;
						color: $cl-gray;
						@include media-maxW(767) {
							font-size: 1.4rem;
						}
		    		/* width */
	          &::-webkit-scrollbar {
	            width: 4px;
	          }

	          /* Track */
	          &::-webkit-scrollbar-track {
	            background: #f1f1f1; 
	          }
	           
	          /* Handle */
	          &::-webkit-scrollbar-thumb {
	            background: $cl-gray; 
	          }

	          /* Handle on hover */
	          &::-webkit-scrollbar-thumb:hover {
	            background: #555; 
	          }
		    	}
		    	&.error {
						div {
							font-size: 1.6rem;
							font-weight: $font-bold;
							color: $cl-cardinal;
							width: 100%;
							margin: 10px 20px 0;
							text-align: left;
							@include media-maxW(767) {font-size: 1.4rem;}
						}
						input,
						textarea {
							border: 1px solid $cl-cardinal;
						}
					}
	    	}
	    	button {
	    		@extend.btn;
          background: $cl-black;
          color: $cl-white;
          padding: 5px 20px;
          font-family: Open Sans;
          font-size: 1.8rem;
          font-weight: $font-light;
          border: none;
          box-sizing: border-box;
          &:after {border: 1px solid $cl-black;}
          &:hover,
          &:focus{
          	color: $cl-black;
          	background-color: $cl-white;
          	outline: 1px solid $cl-black;
          }
	    	}
	    }
	    .info {
	    	margin-bottom: 40px;
		    h6 {
					font-size: 2.2rem;
					line-height: 1.5;
					margin-bottom: 5px;
		    	@include media-maxW(768) {font-size: 2rem;}
		    }
		    .item {
		    	display: flex;
					align-items: center;
					margin-bottom: 5px;					
			    figure {
			    	display: inline-block;
			    	width: 20px;
			    	margin-right: 15px;
			    	img {width: 100%;}
			    }
			    span {
			    	font-size: 1.8rem;
			    	font-weight: $font-light;
			    	line-height: 1.5;
			    	@include media-maxW(768) {font-size: 1.4rem;}
			    }
		    }
	    }
	    .map {
	    	width: 100%;
	    	height: 400px;
	    }
		}
	}
}