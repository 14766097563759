html.js-mobile {
  text-size-adjust: 100%;
}

@-moz-document url-prefix() {
  #popup-wrap {
    #popup-country {
      .expand-nav {
        overflow: hidden;
        ul { margin-right: -17px; }
      }
    }
  }
}