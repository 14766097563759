*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  width: 100vw;
  height: 100vh;
  background-color: $cl-white;
  font-size: 62.5%;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.js-popup {
    // position: fixed;
    overflow: hidden;
  }
}

body {
  @extend.abs-0-0;
  position: relative;
  width: 100%;
  min-height: 100%;
  font-family: $font-name;
  font-size: 1.8rem;
  overflow: hidden;

  @include media-maxW(960) {
    font-size: 1.4rem;
  }

  &.js-menu-active {
    overflow: hidden;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

input {
  font-family: $font-name;
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
  color: $cl-shady-lady;
  font-family: $font-name;
  font-weight: 300;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

main {
  position: relative;
  width: 100vw;
}

a {
  transition: color 0.25s ease;
  color: $cl-black;
  outline: none;
  text-decoration: none;
}

button {
  outline: none;
  font-family: "Open Sans";
}

img {
  vertical-align: top;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

input,
textarea {
  border: none;
  outline: none;
  box-shadow: none;
  appearance: none;
  font-family: "Open Sans";
}

select {
  outline: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

.mb-show {
  display: none;

  @include media-maxW(767) {
    display: block;
  }
}

.mb-hide {
  @include media-maxW(767) {
    display: none;
  }
}

.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.underlink {
  display: block;
  position: relative;

  @include hover {
    &:after {
      width: 100%;
    }
  }

  &.active:after {
    width: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -3px;
    left: 0;
    transition: 0.25s width;
  }
}

#overlay {
  @extend.base-overlay;
  display: none;
  z-index: 2;
}

.banner-holder {
  @extend.full-scr;
  .slider {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .item {
      @extend.abs-0-0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      visibility: hidden;

      img {
        @extend.center-img;
        @include media-maxW(960) {
          height: 100%;
        }

        &.dt {
          @include media-maxW(480) {
            display: none;
          }
        }
    
        &.mb {
          display: none;
    
          @include media-maxW(480) {
            display: block;
          }
        }
      }

      video {
        width: 100%;
        height: 100%;
        background: $cl-black;
      }

      .module-home-video {
        width: 100%;
        height: 100%;
      }

      .slider-text {
        position: absolute;
        top: 65%;
        left: 80px;
        width: 55%;
        transform: translateY(-50%);
        color: $cl-white;
        z-index: 2;
  
        @include media-maxW(1024) {
          top: unset;
          bottom: 75px;
          left: 50%;
          width: 80%;
          transform: translateX(-50%);
          text-align: center;
        }
  
        h3 {
          font-size: 46px;
          font-weight: $font-bold;
          line-height: 1.5;
  
          @include media-maxW(960) {
            font-size: 2.4rem;
          }
        }
  
        p {
          margin-top: 10px;
          font-size: 22px;
          font-weight: $font-light;
          line-height: 1.4;
  
          @include media-maxW(960) {
            font-size: 1.4rem;
          }
        }
      }

      .gradient {
        width: 100%;
        height: 80%;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.7) 60%
        );
        z-index: 1;
  
        @include media-maxW(1024) {
          height: 50%;
        }
      }
    }
  }

  .paging {
    position: absolute;
    z-index: 1;
  
    &.type-1 {
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
  
      li {
        display: inline-block;
        margin-right: 15px;
  
        @include media-maxW(960) {
          margin-right: 10px;
        }
  
        &:last-child {
          margin-right: 0;
        }
      }
    }
  
    &.type-2 {
      top: 50%;
      right: 18px;
      transform: translateY(-50%);
  
      @include media-maxW(480) {
        right: 10px;
      }
  
      li {
        display: block;
        margin-bottom: 15px;
  
        @include media-maxW(960) {
          margin-bottom: 10px;
        }
  
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  
    a {
      display: block;
      position: relative;
      width: 18px;
      height: 18px;
      background-color: $cl-dove-gray;
      border: 2px solid $cl-white;
      border-radius: 50%;
      text-indent: -9999px;
  
      @include hover {
        &:after {
          transform: scale(1);
        }
      }
  
      @include media-maxW(960) {
        width: 12px;
        height: 12px;
        border-width: 1px;
      }
  
      &.js-active:after {
        transform: scale(1);
      }
  
      &:after {
        @extend.abs-0-0;
        content: "";
        width: 14px;
        height: 14px;
        transform: scale(0);
        transform-origin: center;
        transition: 0.25s transform;
        background-color: $cl-red;
        border-radius: 50%;
  
        @include media-maxW(960) {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  .btn-scroll {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    animation: scale 2s linear 0s infinite normal both;
    z-index: 2;

    span {
      @extend.arrow-down;
      @extend.center-xy;
      @include arrow-y(32px, 18px, 3px);
      @include media-maxW(960) {
        @include arrow-y(26px, 14px, 2px);
      }
    }
  }
}

.container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;

  @include media-maxW(1300) {
    padding: 0 80px;
  }
  @include media-maxW(960) {
    padding: 0 20px;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;

  .equal-w {
    flex: 1;
  }
}

.title {
  font-size: 40px;
  font-weight: $font-bold;
  line-height: 1.5;
  text-align: center;

  @include media-maxW(768) {
    font-size: 30px;
  }
}

.sub-title {
  color: $cl-gray;
  font-size: 2.4rem;
  text-align: center;
  line-height: 1.5;

  @include media-maxW(960) {
    font-size: 1.5rem;
  }
}

.slick-prev,
.slick-next {
  z-index: 99;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 5%;
    height: 40px;
    background: $cl-white;
  }

  &:before {
    top: -30px;
    opacity: 1;
  }
}

.slick-prev {
  &:before {
    transform: skew(145deg, 0deg);
  }

  &:after {
    transform: skew(-145deg, 0deg);
  }
}

.slick-next {
  &:before {
    transform: skew(35deg, 0deg);
  }

  &:after {
    transform: skew(-35deg, 0deg);
  }
}

.slick-dots {
  position: relative;
  bottom: 0;
  margin-top: 30px;

  @include media-maxW(960) {
    margin-top: 15px;
  }

  li {
    button {
      &:before {
        color: $cl-boulder;
        font-size: 12px;
        opacity: 1;
      }
    }

    &.slick-active {
      button {
        &:before {
          color: $cl-mojo;
          opacity: 1;
        }
      }
    }
  }
}

.slick-slide {
  outline: none;
}

.banner {
  position: relative;
  width: 100%;
  height: 330px;

  @include media-maxW(960) {
    height: 200px;
  }

  figure {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      @extend.center-img;
      height: 100%;
    }

    .gradient {
      @include gradient(70%, 100%, left);
      @include media-maxW(960) {
        @include gradient(100%, 30%, bottom);
      }
    }
  }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 1280px;
    transform: translate(-50%, -50%);

    @include media-maxW(1300) {
      padding: 0 40px;
    }

    @include media-maxW(960) {
      top: unset;
      bottom: 10px;
      padding: 0 5px;
      transform: translate(-50%, 0);
    }

    h2 {
      color: $cl-white;
      font-size: 5.8rem;
      line-height: 1.2;

      @include media-maxW(960) {
        font-size: 4rem;
        text-align: center;
      }

      @include media-maxW(480) {
        font-size: 2.4rem;
      }
    }
  }
}

.emphasized-block {
  position: relative;
  width: 100%;
  border: 1px solid $cl-cardinal;
  border-radius: 10px;

  .title {
    font-size: unset;

    h4,
    .span {
      display: inline-block;
      position: relative;
      padding: 5px 0;
      transform: translateY(-50%);
      color: $cl-black;
      background: url("../img/icon/mid-block.png") repeat-x;
      background-color: $cl-white;
      background-size: auto 100%;
      font-size: 2rem;
      font-weight: $font-bold;

      @include media-maxW(960) {
        font-size: 1.6rem;
      }
      @include media-maxW(767) {
        font-size: 1.2rem;
      }
      @include media-maxW(480) {
        max-width: 200px;
      }

      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        width: 20px;
        height: 100%;
        background: url("../img/icon/block.png");
        background-color: $cl-white;
        background-size: 100% 100%;
        z-index: 3;
      }

      &:before {
        left: -20px;
      }

      &:after {
        right: -20px;
        transform: rotate(180deg);
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

.controls {
  margin: 40px 0;
  display: flex;
  flex-wrap: wrap;

  form {
    width: 100%;
  }

  .sort {
    display: flex;
    align-items: center;
    width: 200px;
    height: 30px;
    margin-right: 10px;
    position: relative;
    padding: 5px 10px;
    background: url(../img/icon/icon-dropdown.png) 96%/12px no-repeat #fff;
    border: 1px solid $cl-black;
    font-size: 1.4rem;
    line-height: 1;
    cursor: pointer;

    @include media-maxW(767) {
      order: 2;
      width: calc(100% - 30px);
      margin-right: 0;
    }
  
    .current {
      font-size: 1.4rem;
    }
  
    .list {
      display: none;
      position: absolute;
      top: 29px;
      left: -1px;
      width: 100%;
      max-height: 145px;
      color: $cl-gray;
      background: $cl-black;
      border: 1px solid $cl-black;
      border-top: none;
      box-sizing: content-box;
      overflow-x: hidden;
      overflow-y: scroll;
      z-index: 2;
  
      &::-webkit-scrollbar {
        width: 4px;
      }
  
      &::-webkit-scrollbar-track {
        background: $cl-black;
      }
  
      &::-webkit-scrollbar-thumb {
        background: $cl-gray;
      }
  
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
  
      .option {
        padding: 10px;
        font-size: 1.4rem;
        text-align: center;
  
        @include media-maxW(767) {
          font-size: 1.4rem;
        }
  
        &:hover {
          color: $cl-white;
        }
      }
    }
  }

  .datepicker {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 10px;
    margin-right: 10px;
    border: 1px solid $cl-black;

    @include media-maxW(767) {
      order: 1;
      justify-content: center;
      width: 30px;
      padding: 0;
      margin-right: 0;
      background-color: $cl-light-grey;
      border-right: none;
    }

    input {
      width: 200px;
      height: 100%;
      padding-right: 10px;
      color: $cl-black;
      font-size: 1.4rem;
      cursor: pointer;

      @include media-maxW(767) {
        display: none;
      }

      &::placeholder {
        color: $cl-black;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: $cl-black;
      }

      &::-ms-input-placeholder {
        color: $cl-black;
      }
    }

    img {
      width: 20px;
    }
  }

  .search {
    display: flex;
    position: relative;
    height: 30px;

    @include media-maxW(767) {
      order: 3;
      width: 100%;
      margin-top: 10px;
    }

    figure {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 100%;
      cursor: pointer;

      @include media-maxW(767) {
        background-color: $cl-light-grey;
        border: 1px solid $cl-black;
        border-right: none;
      }

      &.js-active {
        background-color: $cl-light-grey;
        border: 1px solid $cl-black;
        border-right: none;
        animation: fadeIn;
        animation-duration: 0.5s;
      }

      img {
        @extend.center-xy;
        width: 20px;
        z-index: 2;
      }
    }

    .box {
      width: 200px;
      height: 100%;
      overflow: hidden;

      @include media-maxW(767) {
        width: calc(100% - 30px);
      }

      input {
        display: none;
        width: 100%;
        height: 100%;
        padding-left: 10px;
        color: $cl-black;
        border: 1px solid $cl-black;
        border-radius: 0;
        font-size: 1.4rem;
        font-weight: $font-light;

        @include media-maxW(767) {
          display: inline-block;
        }

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $cl-black;
          opacity: 1; /* Firefox */
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $cl-black;
        }
        
        &::-ms-input-placeholder { /* Microsoft Edge */
          color: $cl-black;
        }

        &.js-active {
          display: inline-block;
          animation-name: fadeInLeft;
          animation-duration: 0.5s;
        }
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  list-style: none;

  .page-item {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover,
    &.active {
      .page-link {
        color: $cl-black;
        font-weight: $font-bold;
      }
    }

    .page-link {
      padding-left: 1rem;
      padding-right: 1rem;
      color: rgba(0, 0, 0, 0.4);
      font-size: 2rem;
    }
  }
}

.popup-alert {
  display: none;
  position: fixed;
  top: 25%;
  left: 0;
  width: 100%;
  margin: 0 auto;
  font-size: 1.8rem;
  z-index: 2;

  @include media-maxW(767) {
    top: 10%;
  }

  &.active {
    display: block;
  }

  .container {
    width: 100%;
    max-width: 1200px;
    height: 100%;

    @include media-maxW(1300) {
      padding: 0 60px;
    }
    @include media-maxW(960) {
      padding: 0 10px;
    }

    .content {
      position: relative;
      padding: 35px;
      background-color: $cl-silver;
      border-radius: 20px;
      line-height: 1.5;
      text-align: justify;
      text-align-last: center;

      @include media-maxW(767) {
        padding: 35px 15px 20px;
      }

      .close {
        display: inline-block;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 25px;
        height: 25px;
        overflow: hidden;
        cursor: pointer;

        @include media-maxW(767) {
          width: 15px;
          height: 15px;
        }

        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 2px;
          margin-top: -1px;
          background: #000;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }

      .head-popup {
        margin-bottom: 30px;
        font-size: 3rem;
        font-weight: $font-bold;
        text-align: center;
        text-align-last: center;

        @include media-maxW(767) {
          margin-bottom: 10px;
          font-size: 1.6rem;
        }
      }

      .desc-popup {
        font-size: 1.8rem;
        font-weight: $font-light;

        @include media-maxW(767) {
          font-size: 1.4rem;
        }

        form {
          margin: 0 -30px;

          .item {
            position: relative;
            width: 50%;
            margin-bottom: 20px;
            padding: 0 30px;
            text-align: left;

            @include media-maxW(767) {
              width: 100%;
              margin-bottom: 10px;
            }

            .group {
              display: flex;
              margin-bottom: 10px;

              @include media-maxW(767) {
                flex-direction: column;
              }

              label {
                display: inline-block;
                width: 150px;
                font-size: 2.2rem;
                font-weight: $font-regular;

                @include media-maxW(767) {
                  display: flex;
                  width: 100%;
                  font-size: 1.6rem;
                }

                span {
                  color: $cl-cardinal;
                  font-weight: $font-bold;
                }
              }

              input {
                width: calc(100% - 150px);
                height: 40px;
                padding-left: 15px;
                font-size: 2.2rem;
                text-align-last: left;

                @include media-maxW(767) {
                  width: 100%;
                  font-size: 1.6rem;
                }
              }
            }

            &.error {
              .error {
                width: 100%;
                padding-left: 150px;
                color: $cl-cardinal;
                font-size: 1.6rem;
                font-weight: $font-bold;

                @include media-maxW(767) {
                  display: flex;
                  padding-left: 15px;
                  font-size: 1.4rem;
                }
              }

              input,
              textarea {
                border: 1px solid $cl-cardinal;
              }
            }

            &.control {
              display: block;
              width: 100%;
              margin-bottom: 5px;
              text-align: right;

              label[for="file"] {
                width: unset;
                margin-right: 10px;
                padding: 5px 20px;
                color: $cl-white;
                background: $cl-black;
                border: none;
                font-family: Open Sans;
                font-size: 1.8rem;
                font-weight: $font-light;
                text-align: center;
                text-align-last: center;
                cursor: pointer;

                @include media-maxW(767) {
                  display: block;
                  width: 100%;
                  margin-bottom: 10px;
                }

                span {
                  color: $cl-cardinal;
                  font-weight: $font-bold;
                }
              }

              button {
                @extend.btn;
                height: 34px;
                padding: 4px 20px;
                color: $cl-white;
                background: $cl-black;
                border: none;
                font-family: Open Sans;
                font-size: 1.8rem;
                font-weight: $font-light;

                @include media-maxW(767) {
                  width: 100%;
                }

                &:after {
                  border: 1px solid $cl-black;
                }

                &:hover,
                &:focus {
                  color: $cl-black;
                  background-color: $cl-white;
                  outline: 1px solid $cl-black;
                }
              }
            }
          }
        }
      }
    }
  }
}

.popup-bod {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: rgba(0,0,0, 0.5);
  font-size: 1.8rem;
  z-index: 2;

  &.active {
    display: block;
  }

  .container {
    @extend.center-xy;
    width: 100%;
    max-width: 1100px;
    padding: 0 20px;

    .content {
      position: relative;
      padding: 20px 15px 20px;
      background-color: $cl-concrete;
      border-radius: 20px;
      text-align: center;
      line-height: 1.5;

      .close {
        display: inline-block;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 15px;
        height: 15px;
        overflow: hidden;
        cursor: pointer;

        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 2px;
          margin-top: -1px;
          background: #000;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }

      .head-popup {
        margin-bottom: 10px;
        text-align: center;

        figure {
          width: 100%;
          margin-bottom: 10px;

          img {
            max-width: 130px;
          }
        }

        .text {
          font-size: 1.4rem;
          line-height: 1.5;

          &.name {
            margin-bottom: 10px;
            font-weight: $font-bold;
          }
        }
      }

      .desc-popup {
        height: 250px;
        padding-right: 20px;
        font-size: 1.4rem;
        font-weight: $font-light;
        line-height: 1.5;
        overflow: scroll;
      }
    }
  }

  strong {font-weight: $font-bold;}
  em {font-style: italic;}
  ul {
    list-style-type: square;
    margin-left: 20px;
    li {line-height: 1.5;}
  }
  ol {
    list-style-type: decimal;
    margin-left: 20px;
    li {line-height: 1.5;}
  }
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: rgba(0,0,0, 0.5);
  font-size: 1.8rem;
  z-index: 5;

  &.active {
    display: block;
  }

  .container {
    @extend.center-xy;
    width: 100%;
    max-width: 1100px;
    padding: 0 20px;

    .close {
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 28px;
      width: 15px;
      height: 15px;
      overflow: hidden;
      cursor: pointer;
      z-index: 3;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        margin-top: -1px;
        background: #000;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    .content {
      position: relative;
      padding: 20px 15px 20px;
      background-color: $cl-concrete;
      border-radius: 20px;
      text-align: center;
      line-height: 1.5;
      min-height: 200px;
      max-height: calc(100vh - 80px);
      overflow-y: auto;
    }
  }

  strong {font-weight: $font-bold;}
  em {font-style: italic;}
  ul {
    list-style-type: square;
    margin-left: 20px;
    li {line-height: 1.5;}
  }
  ol {
    list-style-type: decimal;
    margin-left: 20px;
    li {line-height: 1.5;}
  }
}


.hexagon {
  position: relative;
  background: $cl-black;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0px;
    width: 0;
    height: 0;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
  }
  
  &:before {
    left: -13px;
    border-right: 13px solid $cl-black;
  }

  &:after {
    right: -13px;
    border-left: 13px solid $cl-black;
  }
}

.link {
  @extend.underlink;
  display: inline-block;
  color: $cl-atoll;

  &:after {
    background-color: $cl-black;
  }
}
