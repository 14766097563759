#page-report {
  .section-data {
    padding-top: 50px;
    @include media-maxW(767) {padding-top: 20px;}
    .block-search {
      margin-left: 2rem;
    }
    .section-content {
      .block-table {
        .block-header {
          display: grid;
          grid-template-columns: 25% 55% 20%;

          @media screen and (max-width: 600px) {
            grid-template-columns: 70% 30%;
          }
          .block-title {
            color: #ffffff;
            background-color: #000000;
            font-size: 1.7rem;
            font-weight: $font-light;
            line-height: 1.5;
            padding: 0.6rem 1.5rem;
            &.flex-center {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            &:nth-child(1) {
              @media screen and (max-width: 600px) {
                display: none;
              }
            }
            &:nth-child(2) {
              border-left: 1px solid $cl-white;
              border-right: 1px solid $cl-white;
            }
          }
        }
        .block-content {
          .block-record {
            text-align: justify;
            display: grid;
            grid-template-columns: 25% 55% 20%;
            @media screen and (max-width: 600px) {
              grid-template-columns: 70% 30%;
            }
            .block-cell {
              color: #000000;
              font-size: 1.8rem;
              font-weight: $font-light;
              line-height: 1.5;
              padding: 0.6rem 1.5rem;
              @include media-maxW(767) {font-size: 1.4rem;}
              &:nth-child(1) {
                text-align: center;
                strong {
                  font-size: 1.5rem;
                  font-weight: $font-bold;
                  margin-right: 0.5rem;
                }
                @include media-maxW(767) {text-align: left;}
                @media screen and (min-width: 600px) {
                  strong {
                    display: none;
                  }
                }
                @media screen and (max-width: 600px) {
                  grid-column: 1 / 2;
                  grid-row: 2;
                }
              }
              &:nth-child(2) {
                @media screen and (min-width: 600px) {
                  border-left: 1px solid #000000;
                  border-right: 1px solid #000000;
                }
                @media screen and (max-width: 600px) {
                  grid-column: 1 / 2;
                  grid-row: 1;
                }
              }
              &:nth-child(3) {
                display: flex;
                justify-content: center;
                align-items: center;
                @media screen and (max-width: 600px) {
                  grid-column: 2;
                  grid-row: 1 / 3;
                }
              }
            }

            &:nth-of-type(odd) {
              .block-cell {
                background-color: $cl-iron;
              }
            }

            &:nth-of-type(even) {
              .block-cell {
                background-color: #ffffff;
              }
            }
          }
        }
      }
    }
    .section-footer {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}
