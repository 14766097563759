#page-news {
  .news {
    margin: 40px 0;
    @include media-maxW(480) {margin: 20px 0};
    .container {
      article {
        margin: 0 -10px;
        @include media-maxW(767) {display: unset;}
        .horizon-news {
          width: 100%;
          padding: 0 10px;
          margin-bottom: 20px;
          @include media-maxW(767) {
            width: 100%;
            max-width: 440px;
            padding: 0;
            margin: 0 auto 20px;
          }
          @include media-maxW(480) {
            max-width: 100%;
            padding: 0;
          }
          .flex {
            background-color: #e7e7e9;
            overflow: hidden;
            @include media-minW(767) {
              &:hover {
                .image {
                  width: calc(60% - 10px);
                  figure {
                    filter: brightness(85%);
                    transform: scale(1.05);
                  }
                }
                .title {
                  width: calc(40% + 10px);
                  padding-left: 60px;
                  .description {
                    opacity: 1;
                    animation-name: fadeInLeft;
                    animation-duration: 0.2s;
                  }
                }
              }
            }
            .image {
              order: 2;
              width: 60%;
              height: 400px;
              overflow: hidden;
              transition: 0.2s;
              @include media-maxW(767) {
                width: 100%;
                height: 350px;
                order: 1;
              }
              figure {
                width: 100%;
                height: 100%;
                position: relative;
                transition: 0.2s;
                img {
                  @extend.center-img;
                  height: 100%;
                }
              }
            }
            .title {
              order: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 40%;
              padding: 50px;
              color: $cl-black;
              text-align: justify;
              transition: 0.2s;
              @include media-maxW(767) {
                order: 2;
                width: 100%;
                padding: 2.5rem;
                font-size: 1.6rem;
              }
              .category-name,
              time {
                font-size: 1.5rem;
                font-weight: $font-light;
                font-style: italic;
                line-height: 1.25;
                color: $cl-gray;
              }
              time {
                display: block;
                margin-bottom: 16px;
              }
              h4 {
                font-weight: $font-bold;
                font-size: 2rem;
                line-height: 1.5;
                margin-bottom: 10px;
              }
              .description {
                opacity: 0;
                font-size: 16px;
                font-weight: $font-light;
                line-height: 1.5;
                text-transform: none;
                @include media-maxW(960) {display: none;}
              }
            }
          }
        }
        .vertical-news {
          width: 50%;
          padding: 0 10px;
          margin-bottom: 20px;
          @include media-maxW(767) {
            width: 100%;
            max-width: 440px;
            padding: 0;
            margin: 0 auto 20px;
          }
          @include media-maxW(480) {
            max-width: 100%;
            padding: 0;
          }
          .flex {
            background-color: #e7e7e9;
            transition: 1s;
            @include media-minW(767) {
              &:hover { 
                figure {
                  filter: brightness(85%);
                  transform: scale(1.1);
                }
              }
            }
            .image {
              width: 100%;
              height: 350px;
              overflow: hidden;
              figure {
                position: relative;
                width: 100%;
                height: 350px;
                transition: 0.2s;
                overflow: hidden;
                img {
                  @extend.center-img;
                  height: 100%;
                }
              }
            }
            .title {
              position: relative;
              width: 100%;
              padding: 25px 50px;
              text-align: justify;
              color: $cl-black;
              @include media-maxW(767) {
                font-size: 1.6rem;
                padding: 25px;
              }
              .category-name, time {
                font-size: 1.5rem;
                font-weight: $font-light;
                font-style: italic;
                line-height: 1.25;
                color: $cl-gray;
              }
              time {
                display: block;
                margin-bottom: 16px;
              }
              h4 {
                font-weight: $font-bold;
                font-size: 2rem;
                line-height: 1.5;
                margin-bottom: 10px;
              }
              .description {
                position: absolute;
                bottom: 25px;
                left: 0;
                width: 100%;
                padding: 0 50px;
                font-size: 16px;
                font-weight: $font-light;
                line-height: 1.5;
                text-transform: none;
                opacity: 0;
                @include media-maxW(960) {display: none;}
              }
            }
          }
        }
      }
    }
  }
}
