#page-community {
  .news {
    margin: 40px 0;
    @include media-maxW(1200) {
      margin: 40px 0;
    }
    @include media-maxW(480) {
      margin: 20px 0;
    }
    .container {
      .sort {
        margin-bottom: 20px;
        .option {
          position: relative;
          width: 220px;
          height: 40px;
          @include media-maxW(960) {
            width: 160px;
            height: 30px;
          }
          select {
            width: 100%;
            height: 100%;
            padding-left: 20px;
            border: 1px solid $cl-black;
            font-size: 2rem;
            background: url("../img/search/icon-dropdown.png") 96% / 12%
              no-repeat $cl-white;
            @include media-maxW(960) {
              padding-left: 10px;
              font-size: 1.6rem;
            }
            option {
              background-color: $cl-black;
              color: $cl-white;
              padding: 10px 0;
            }
          }
        }
        .search {
          height: 40px;
          margin-left: 10px;
          @include media-maxW(960) {
            height: 30px;
          }
          img {
            height: 100%;
            cursor: pointer;
          }
        }
      }
      article {
        margin: 0 -10px;
        @include media-maxW(960) {
          display: unset;
        }
        .horizon-news {
          width: 100%;
          padding: 0 10px;
          margin-bottom: 20px;
          @include media-maxW(960) {
            width: 100%;
            max-width: 440px;
            padding: 0;
            margin: 0 auto 20px;
          }
          @include media-maxW(480) {
            max-width: 100%;
            padding: 0;
          }
          figure {
            width: 60%;
            order: 2;
            @include media-maxW(960) {
              width: 100%;
              order: 1;
            }
            img {
              width: 100%;
            }
          }
          .title {
            order: 1;
            width: 40%;
            background-color: #e7e7e9;
            background-color: #e7e7e9;
            color: $cl-black;
            font-size: 2rem;
            @include media-maxW(960) {
              font-size: 1.6rem;
              width: 100%;
              order: 2;
              text-align: left;
            }
            .category-name,
            time {
              font-weight: $font-light;
              line-height: 1.25;
            }
            time {
              display: block;
              margin-bottom: 16px;
            }
            h4 {
              font-weight: $font-bold;
              line-height: 1.5;
            }
            padding: 2.5rem;

            @media screen and (min-width: 600px) {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }

            @media screen and (min-width: 1200px) {
              padding: 5rem;
            }
          }
        }
        .vertical-news {
          width: 50%;
          padding: 0 10px;
          margin-bottom: 20px;
          @include media-maxW(960) {
            width: 100%;
            max-width: 440px;
            padding: 0;
            margin: 0 auto 20px;
          }
          @include media-maxW(480) {
            max-width: 100%;
            padding: 0;
          }
          figure {
            width: 100%;
            img {
              width: 100%;
            }
          }
          .title {
            width: 100%;
            background-color: #e7e7e9;
            background-color: #e7e7e9;
            color: $cl-black;
            font-size: 2rem;
            @include media-maxW(960) {
              font-size: 1.6rem;
              text-align: left;
            }
            .category-name,
            time {
              font-weight: $font-light;
              line-height: 1.25;
            }
            time {
              display: block;
              margin-bottom: 16px;
            }
            h4 {
              font-weight: $font-bold;
              line-height: 1.5;
            }
            padding: 2.5rem;

            @media screen and (min-width: 600px) {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }

            @media screen and (min-width: 1200px) {
              padding: 5rem;
            }
          }
        }
      }
    }
  }
}
