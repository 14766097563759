#page-detail-policy {
  .detail-policy {
    margin: 100px auto;
    @include media-maxW(767) {margin: 40px 0};
    figure {
      width: 100%;
      margin-top: 40px;
      @include media-maxW(767) {margin-top: 20px;}
      img {width: 100%;}
    }
    .container {
     .list {
      @extend.flex;
      margin: 60px -20px 0;
      @include media-maxW(767) {margin: 20px -20px 0;}
      .item {
        flex-grow: 1;
        flex-basis: 0;
        padding: 0 20px;
        @include media-maxW(767) {
          flex-grow: unset;
          flex-basis: unset;
          width: 100%;
          margin-bottom: 10px;
        }
        .title-item {
          font-size: 2.4rem;
          line-height: 1.8;
          margin-bottom: 20px;
          text-align: justify;
          text-align-last: center;
          @include media-maxW(767) {display: none;}
        }
        .description-item {
          font-size: 1.8rem;
          font-weight: $font-light;
          line-height: 1.8;
          text-align: justify;
          @include media-maxW(767) {
            font-size: 1.4rem;
            text-align-last: center; 
          }
        }
      }
     }
    }
  }
}