#page-bod {
  .manager {
    .container {
      .bod {
        margin-bottom: 100px;

        @include media-maxW(1200) {
          margin: 60px 0;
        }

        @include media-maxW(767) {
          margin-top: 24px;
        }

        @include media-maxW(480) {
          margin: 40px 0;
        }

        .bod-item {
          width: 100%;
          max-width: 33.33%;
          margin: 20px 0;
          text-align: center;

          @include media-maxW(767) {
            max-width: 50%;
          }

          @include media-maxW(480) {
            max-width: 100%;
          }

          .bod-info {
            width: 100%;
            max-width: 25.5rem;
            margin: 0 auto 2.5rem;

            @include media-maxW(1200) {
              max-width: 20rem;
              margin-bottom: 1.5rem;
            }

            figure {
              width: 100%;
              height: auto;
              margin-bottom: 2.5rem;

              img {
                width: 100%;
                transition: 1s;
                cursor: pointer;

                &:hover {
                  transform: scale(1.1, 1.1);

                  @include media-maxW(767) {
                    transform: none;
                  }
                }
              }
            }

            figcaption {
              padding: 20px 0;

              &.active {
                background-color: $cl-iron;
                border-radius: 10px;
              }

              h3 {
                display: block;
                margin-bottom: 5px;
                font-size: 2.2rem;
                font-weight: $font-bold;
                text-align: center;
                line-height: 1.4;

                span {
                  display: block;
                  font-size: 1.4rem;
                  text-transform: capitalize;
                }

                @include media-maxW(960) {
                  font-size: 1.6rem;

                  span {
                    font-size: 1.2rem;
                  }
                }
              }

              .desc {
                font-size: 1.4rem;
                text-align: center;
                line-height: 1.4;

                @include media-maxW(960) {
                  font-size: 1.2rem;
                }
              }
            }
          }

          &.bod-active {
            margin-bottom: 48rem;
          }

          .bod-detail {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            height: auto;
            padding: 4rem;
            transition: 0.3s ease-in-out 0s;

            .wrap {
              display: flex;
              flex-wrap: wrap;
              position: relative;
              width: 100%;
              padding: 4rem;
              background-color: #e7e7e9;
              border-radius: 3rem;

              .img-detail {
                width: 30%;

                figure {
                  width: 100%;
                  height: auto;
                  padding-bottom: 125%;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;
                  background-color: $cl-white;
                }
              }

              .copy-detail {
                width: 70%;
                padding: 0 4rem;

                h3 {
                  display: block;
                  margin-bottom: 1rem;
                  font-size: 2.2rem;
                  font-weight: $font-bold;
                  text-align: left;
                  line-height: 1.4;

                  span {
                    font-size: 1.4rem;
                  }
                }

                .sub-title {
                  margin-bottom: 2rem;
                  color: #666666;
                  font-size: 1.6rem;
                  text-align: left;
                  line-height: 1.2;
                }

                .desc-detail {
                  font-size: 2rem;
                  font-weight: $font-light;
                  text-align: justify;
                  text-align-last: left;
                  line-height: 1.2;
                  overflow: hidden;
                }
              }
            }
          }
        }

        .bod-des {
          display: block;
          position: relative;
          width: 90%;
          margin: 0 auto 20px;
          padding: 30px;
          background-color: $cl-iron;
          border: none;
          border-radius: 30px;

          @include media-maxW(767) {
            padding: 20px;
          }

          @include media-maxW(480) {
            width: 95%;
            padding: 10px;
          }

          .close {
            display: inline-block;
            position: absolute;
            top: 10px;
            right: 10px;
            width: 25px;
            height: 25px;
            overflow: hidden;
            cursor: pointer;

            &:before,
            &:after {
              content: "";
              position: absolute;
              top: 50%;
              left: 0;
              width: 100%;
              height: 2px;
              margin-top: -1px;
              background: #000;
            }

            &:before {
              transform: rotate(45deg);
            }

            &:after {
              transform: rotate(-45deg);
            }
          }

          figure {
            width: 30%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
            }
          }

          figcaption {
            width: 70%;
            padding: 15px 0 0 30px;
            overflow: hidden;

            @include media-maxW(480) {
              padding: 5px 0 0 10px;
            }

            .name {
              margin-bottom: 10px;
              font-weight: $font-bold;

              span {
                &:first-child {
                  font-size: 1.4rem;
                }

                &:nth-child(2) {
                  font-size: 2.2rem;

                  @include media-maxW(1200) {
                    font-size: 2rem;
                  }

                  @include media-maxW(767) {
                    font-size: 1.6rem;
                  }
                }
              }
            }

            .position {
              margin-bottom: 20px;
              color: $cl-gray;
              font-size: 1.5rem;
            }

            .description {
              height: 75%;
              padding-right: 20px;
              font-size: 1.8rem;
              font-weight: $font-light;
              text-align: justify;
              line-height: 1.5;
              overflow: auto;

              @include media-maxW(1200) {
                font-size: 1.8rem;
              }

              @include media-maxW(767) {
                font-size: 1.6rem;
              }

              &::-webkit-scrollbar {
                width: 4px;
              }

              &::-webkit-scrollbar-track {
                background: #f1f1f1;
              }

              &::-webkit-scrollbar-thumb {
                background: $cl-black;
              }

              &::-webkit-scrollbar-thumb:hover {
                background: #555;
              }
            }
          }
        }
      }

      .bod-des {
        display: none;
      }

      .controls {
        margin-bottom: 40px;
        
        @include media-maxW(768) {
          margin: 0 -20px;
			    text-transform: uppercase;
        }

        .sort {
          z-index: 1;

          @include media-maxW(768) {
            width: 100%;
            border: none;
            height: 60px;
          }

          .list {
            margin-left: 0;

            @include media-maxW(768) {
              top: 60px;
              background: $cl-white;
              border: none;
              overflow-y: auto;
    
              &::-webkit-scrollbar {
                display: none;
              }
            }
          }

          .option {
            text-align: left;

            @include media-maxW(768) {
              width: auto !important;
              text-align: left;
              font-size: 16px;
              color: $cl-white;
              color: $cl-black;
              font-weight: 100;
              padding: 0px 40px;

              div {
                padding: 12px 20px;					
                border-bottom: solid 1px #E7E7EA;
              }
    
              &.active {
                background-color: $cl-black;
                color: $cl-white;
    
                div {
                  border: none;
                  color: $cl-white;
                }
              }
            }
          }

          .current {
            @include media-maxW(768) {
              padding-left: 30px;
              text-transform: uppercase;
              font-size: 20px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  strong {font-weight: $font-bold;}
  em {font-style: italic;}
  ul {
    list-style-type: square;
    margin-left: 20px;
    li {line-height: 1.5;}
  }
  ol {
    list-style-type: decimal;
    margin-left: 20px;
    li {line-height: 1.5;}
  }
}
