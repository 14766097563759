#page-detail-project {
  .logo-project {
    margin: 40px 0;

    @include media-maxW(480) {
      margin: 20px 0;
    }

    figure {
      width: 100%;
      text-align: center;

      img {
        max-width: 180px;
        margin-bottom: 20px;

        @include media-maxW(768) {
          max-width: 120px;
        }
      }

      figcaption {
        font-size: 3rem;
        font-weight: $font-regular;
        line-height: 1.5;

        @include media-maxW(768) {
          font-size: 1.8rem;
          text-align: justify;
          text-align-last: center;
        }
      }
    }
  }

  .heading-in-page {
    border-top: 1px solid $cl-mercury;
    border-bottom: 1px solid $cl-mercury;
    background-color: $cl-white;
    z-index: 5;

    .container {
      ul {
        width: 100%;
        padding: 20px 0;
        overflow-x: auto;
        white-space: nowrap;
        text-align: right;

        &::-webkit-scrollbar {
          display: none;
        }

        li {
          text-align: center;
          display: inline-block;
          margin: 0 15px;
          padding: 5px;

          &.hexagon {
            .text {
              color: $cl-white;

              &:hover,
              &.active {
                color: $cl-orange-peel;
              }
            }
          }
          .text {
            font-size: 1.1rem;
            font-weight: $font-bold;
            color: $cl-black;
            line-height: 1.5;
            cursor: pointer;

            &:hover,
            &.active {
              color: $cl-black;
            }
          }
        }
      }
    }
  }

  .detail-project {
    margin: 40px 0;

    @include media-maxW(480) {
      margin: 20px 0;
    }

    .container {
      .block {
        margin-bottom: 80px;
      }

      .title {
        margin-bottom: 40px;
        font-size: 40px;
        
        @include media-maxW(768) {
          font-size: 30px;
        }
      }

      p {
        font-size: 1.8rem;
        font-weight: $font-light;
        line-height: 1.8;
        margin-bottom: 10px;
        text-align: justify;

        @include media-maxW(768) {
          font-size: 1.4rem;
        }

        strong {
          font-weight: $font-bold;
        }

        em {
          font-style: italic;
        }
      }

      ul {
        list-style-type: square;
        margin-left: 20px;
        font-weight: $font-light;

        li {
          line-height: 1.8;
        }
      }

      ol {
        list-style-type: decimal;
        margin-left: 20px;
        font-weight: $font-light;

        li {
          line-height: 1.8;
        }
      }

      img {
        width: 100%;
      }

      .overview {
        .list {
          list-style-type: none;
          margin-left: 0;

          .item {
            margin: auto auto 24px auto;
            width: 100%;
            max-width: 850px;

            .sublist {
              list-style-type: none;
              display: flex;

              @include media-maxW(768) {
                margin: 0;
              }

              li {
                font-size: 1.7rem;
                font-weight: $font-light;
                line-height: 1.8;

                @include media-maxW(768) {
                  font-size: 1.4rem;
                }

                &:nth-child(1) {
                  margin-right: 20px;

                  @include media-maxW(768) {
                    margin-right: 10px;
                  }

                  figure {
                    img {
                      width: 20px;
                      margin-top: 16px;
                      transform: translateY(-50%);
                    }
                  }
                }
                &:nth-child(2) {
                  width: 250px;
                  border-bottom: 1px solid $cl-gray;
                  margin-right: 40px;

                  @include media-maxW(768) {
                    width: 170px;
                    margin-right: 10px;
                  }
                }
                &:nth-child(3) {
                  width: 500px;
                  border-bottom: 1px solid $cl-gray;

                  @include media-maxW(768) {
                    width: calc(100% - 210px);
                  }
                }
              }
            }
          }
        }
      }

      .position {
        .maps-type {
          margin-top: 40px;

          .options {
            display: flex;
            width: fit-content;
            margin: auto;
            max-width: 360px;
            width: 90%;        

            .item {
              height: 60px;
              text-align: center;
              width: 50%;
              display: table;
              border: 1px solid $cl-black;
              cursor: pointer;
              margin: auto;

              &.active {
                border: none;
                background: $cl-cardinal;
                color: $cl-white;
                transform: scaleX(1.01);
              }

              span {
                display:table-cell;
                vertical-align:middle;
              }
            }
          }
        }

        .maps {
          .content {
            display: none;

            &.active {
              display: block;
            }

            > * {
              width: 100%
            }
          }
        }
      }

      .versatility {

        p {
          &>img {
            width: 100%;
            height: 100%;
            max-width: 600px;
            margin: 0 calc(50% - 300px);

            @include media-maxW(768) {
              max-width: 100%;
              margin: 0;
            }
          }
        }

        .thumbnail {
          margin-bottom: 40px;
          display: flex;
          align-items: center;

          @include media-maxW(768) {
            flex-direction: column;
          }

          figure {
            width: 50%;
            height: 300px;
            position: relative;
            overflow: hidden;

            @include media-maxW(768) {
              width: 100%;
              height: 60vw;
            }

            img {
              @extend.center-img;
              height: 100%;
              width: unset;
            }
          }

          figcaption {
            width: 50%;
            font-weight: $font-light;
            text-align: left;
            line-height: 1.8;
            font-size: 16px;
            
            @include media-maxW(768) {
              width: 100%;
              margin-top: 20px;
              padding: 0;
            }

            .title {
              font-size: 16px;
              text-align: left;
              margin-bottom: 16px;
            }
            
            p {
              font-size: 16px;
            }
          }

          &:nth-child(even) {
            figure {
              order: 2;
              margin-left: 8px;

              @include media-maxW(768) {
                order: 1;
              }
            }

            figcaption {
              order: 1;
              padding-left: 13%;
              padding-right: 32px;

              @include media-maxW(768) {
                order: 2;
                padding: 0;
              }
            }
          }

          &:nth-child(odd) {
            figure {
              margin-right: 8px;
            }

            figcaption {
              order: 1;
              padding-right: 13%;
              padding-left: 32px;

              @include media-maxW(768) {
                order: 2;
                padding: 0;
              }
            }
          }
        }
      }

      .acreage {
        .acreage-slider {
          figure {
            width: 100%;
            height: 600px;
            position: relative;
            overflow: hidden;

            @include media-maxW(768) {
              height: 300px;
            }

            img {
              @extend.center-img;
              height: 100%;
              width: unset;
            }
          }

          .slick-dots {
            margin-left: 0;
            @extend.slick-dots;
          }
        }

        .acreage-select.slider {
          margin: 40px auto auto;
          display: flex;
          align-items: center;
          justify-content: center;

          @include media-maxW(960) {
            padding: 0 30px;
          }

          @include media-maxW(768) {
            display: none;
          }

          .slick-arrow {
            &::before {
              height: 8px;
              top: 3px;
            }

            &::after {
              height: 8px;
            }
          }

          .item {
            min-width: 150px;
            text-align: center;
            padding: 16px 0;
            border: 1px #707070 solid;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 700;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:not(:first-child) {
              border-left: none;
            }

            &.active {
              background-color: $cl-cardinal;
              color: $cl-white;
              border-color: $cl-cardinal;
              transform: scale(1.01);
            }

            span {
              width: 100%;
              line-height: 1.2;
            }
          }     

          .slick-prev,
          .slick-next {
            width: 70px;
            z-index: 1;
            @extend.center-y;
    
            &:before,
            &:after {
              background-color: $cl-black;
            }
          }
          .slick-prev {
            @extend.slick-prev;
            left: -56px;

            @include media-maxW(960) {left: -26px;}
          }
          .slick-next {
            @extend.slick-next;
            right: -50px;

            @include media-maxW(960) {right: -20px;}
          }
        }

        .acreage-select.dropdown {
          @include media-minW(769) {
            display: none;
          }

          .sort {
            border-color: #E7E7EA;
            text-transform: uppercase;
            font-weight: 600;
            height: 40px;
            width: 100%;

            .current {
              margin: auto;
            }

            .list {
              margin: 0;
              top: 40px;
            }
          }
        }

        .acreage-content {
          margin-top: 40px;

          .item {
            display: none;

            &.active {
              display: block;
            }
          }
        }

        .figures {
          max-width: 1020px;
          margin: 20px auto auto auto;
          font-size: 16px;
          display: flex;
          flex-wrap: wrap;
          padding: 0 24px;

          .item {
            padding-top: 16px;
            line-height: 1.8;
            width: 25%;
            display: flex;

            @include media-maxW(768) {
              font-size: 14px;
              width: 50%;
            }
        
            .order {
              display: inline-block;
              width: 36px;
              height: 36px;
              color: $cl-white;
              border-radius: 100%;
              background-color: $cl-cardinal;
              text-align: center;
              font-weight: $font-light;
              line-height: 2.2;
              margin-right: 14px;
              margin-top: -4px;
            }
          }
        }
      }

      .library {
        margin: 0 0 40px 0;

        @include media-maxW(768) {
          margin: 0 -5px 40px;
        }

        .library-item {
          width: 25%;
          height: 200px;
          padding: 4px;

          @include media-maxW(768) {
            width: 100%;
            padding: 0 5px;
            height: 60vw;
            margin-bottom: 10px;
          }

          figure {
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
            cursor: pointer;

            &.video {
              img {
                width: 100%;
                height: auto;
                min-height: unset;
                transition: none;
              }
            }

            img {
              @extend.center-img;
              height: 100%;
              width: unset;
              transition: all .5s ease-in-out;
            }

            .deg, .icon-360 {
              @extend.center-xy;
              width: 80%;
              height: auto;
              transition: 1s;

              @include media-maxW(768) {
                width: 50%;
              }

              img{
                width: 100%;
                height: auto;
              }
            }

            figcaption {
              padding: 5px 10px;
              width: 100%;
              background-color: $cl-iron;
              line-height: 1.4;
              font-size: 1.8rem;
              font-weight: $font-regular;
              position: absolute;
              left: 0;
              bottom: 0;
              opacity: 0;
              transition: 1s;

              @include media-maxW(768) {
                width: 100%;
                padding: 2px 2px;
                font-size: 1.2rem;
                bottom: 10px;
              }
            }

            &:hover {
              img {
                height: 110%;
              }
              figcaption {
                opacity: 1;
                animation-name: fadeInBottom;
                animation-duration: 1s;
              }
              &.video {
                img {
                  height: auto;
                }
              }
            }
          }

          iframe {
            width: 100%;
            height: 100%;
          }
        }

        .viewmore {
          width: 100%;
          text-align: center;
          font-size: 1.8rem;
          font-weight: $font-regular;
          margin-top: 40px;

          @include media-maxW(768) {
            font-size: 1.4rem;
          }
   
          .viewmore-btn {
            width: 180px;
            margin: auto;
            padding: 16px 0;
            background: $cl-black;
            color: $cl-white;
            font-weight: $font-light;
            cursor: default;
            font-size: 16px;
          }
        }
      }

      .progress {
        .controls {
          @include media-maxW(768) { flex-flow: row-reverse; }
            
          .sort {
            @include media-maxW(768) { width: 130px; }

            .list {
              margin: 0;
            }
          }
        }

        .wrap-item {
          margin: 0 -20px;

          .progress-item {
            width: 33.33%;
            padding: 0 20px;

            @include media-maxW(768) {
              width: 100%;
              margin-bottom: 10px;
          
            }

            figure {
              width: 100%;
              height: 180px;
              margin-bottom: 10px;

              img {
                @extend.img-fit;
              }
            }

            .caption {
              text-align: center;
              font-weight: $font-regular;
            }
          }
        }
      }

      .news {
        .news-slider {
          margin: 0 -15px;

          @include media-maxW(960) {margin: 0 -5px;}
          .news-item {
            padding: 0 15px;

            @include media-maxW(960) {
              padding: 0 5px;
            }

            figure {
              width: 100%;
              height: 250px;
              position: relative;
              overflow: hidden;

              @include media-maxW(768) {
                height: 180px;
              }

              img {
                @extend.center-img;
                height: 100%;
                width: unset;
              }
            }

            figcaption {
              padding: 15px 25px;
              background-color: $cl-iron;

              @include media-maxW(768) {
                padding: 10px;
              }

              p {
                font-size: 1.7rem;
                font-weight: $font-light;
                color: $cl-gray;
                margin: 0;
                line-height: 1;

                @include media-maxW(768) {font-size: 1.2rem;}
              }

              .news-title {
                color: $cl-black;
              }

              .news-date {
                margin-top: 5px;
              }

              .news-shortdes {
                margin-top: 10px;
                color: $cl-black;
                font-size: 1.8rem;
                font-weight: $font-bold;
                line-height: 1.5;

                @include media-maxW(768) {font-size: 1.5rem;}
              }
            }
          }

          .slick-dots {
            margin-left: 0;
            @extend.slick-dots;
          }
        }
      }

      .contact {
        max-width: 900px;
        margin: auto;
    
        .info {
          max-width: 780px;
          margin: auto;
      

          @include media-maxW(768) {padding: 0;}

          .branch-name {
            width: 100%;
            text-align: center;
            font-size: 24px;
            font-weight: $font-bold;
            margin-bottom: 60px;
            @include media-maxW(768) {
              margin-bottom: 20px;
              text-transform: uppercase;
            }
          }

          .info-item {
            padding: 8px 16px;

            @include media-maxW(768) {
              width: 100%;
              text-align: center;

              p {
                text-align: center;
              }
            }

            &.address {
              width: 40%;

              @include media-maxW(768) {width: 100%;}
            }

            &.phone, &.email {
              width: 30%;
              @include media-maxW(768) {
                width: 100%;
                border-top: 1px solid #E7E7EA;
              }
            }

            &.phone {
              .title-info {
                text-align: center;
              }

              .content {
                text-align: center;
              }
            }

            p {
              margin-bottom: 0;
            }

            .title-info {
              font-size: 16px;
              font-weight: $font-bold;
              color: $cl-black;
              line-height: 1.5;

              @include media-maxW(768) {
                font-size: 1.6rem;
              }
            }

            .content {
              word-break: break-word;
              font-size: 15px;
            }

            .link {
              @extend.underlink;
              display: inline-block;

              &:hover {
                color: $cl-atoll;
              }

              &:after {
                background-color: $cl-black;
              }
            }
          }
        }

        form {
          margin: 20px 0;

          .item {
            width: 50%;
            margin-bottom: 20px;
            padding: 0 8px;

            @include media-maxW(768) {
              width: 100%;
            }

            input {
              width: 100%;
              height: 50px;
              border: 1px solid $cl-gray;
              padding: 20px;
              font-size: 1.8rem;
              font-weight: $font-light;
              color: $cl-gray;

              @include media-maxW(768) {
                font-size: 1.4rem;
              }
            }

            &.textarea {
              width: 100%;
              margin: 0 0 20px 0;

              textarea {
                width: 100%;
                height: 200px;
                border: 1px solid $cl-gray;
                font-size: 1.8rem;
                font-weight: $font-light;
                resize: none;
                padding: 10px 20px 0;
                color: $cl-gray;

                @include media-maxW(768) {
                  font-size: 1.4rem;
                }

                &::-webkit-scrollbar {
                  width: 4px;
                }

                &::-webkit-scrollbar-track {
                  background: #f1f1f1;
                }

                &::-webkit-scrollbar-thumb {
                  background: $cl-gray;
                }

                &::-webkit-scrollbar-thumb:hover {
                  background: #555;
                }
              }
            }

            &.error {
              div {
                font-size: 1.6rem;
                font-weight: $font-bold;
                color: $cl-cardinal;
                width: 100%;
                margin: 10px 20px 0;

                @include media-maxW(768) {
                  font-size: 1.4rem;
                }
              }

              input,
              textarea {
                border: 1px solid $cl-cardinal;
              }
            }

            &.button {
              width: 100%;
              text-align: center;

              button {
                @extend.btn;
                display: inline-block;
                margin-bottom: 20px;
                padding: 5px 20px;
                font-size: 1.8rem;
                box-sizing: border-box;

                &:after {
                  border: 1px solid $cl-black;
                }

                &:hover,
                &:focus {
                  color: $cl-black;
                  background-color: $cl-white;
                  outline: 1px solid $cl-black;
                }
              }

              .submit {
                background: $cl-black;
                color: $cl-white;
                font-weight: $font-light;
                border: none;
                width: 180px;
                padding: 16px 0;
              }

              .download {
                background: $cl-white;
                color: $cl-black;
                border: 1px solid $cl-black;
                font-weight: $font-bold;
              }

              .more-detail {
                font-size: 1.8rem;
                font-weight: $font-regular;
                color: $cl-gray;
                margin-bottom: 20px;
                text-align: center;

                @include media-maxW(768) {
                  font-size: 1.4rem;
                }

                .arrow {
                  width: 0;
                  height: 0;
                  border-top: 10px solid transparent;
                  border-left: 10px solid $cl-black;
                  border-bottom: 10px solid transparent;
                  display: inline-block;
                  margin-right: 20px;
                }

                .link {
                  color: $cl-blue;
                }
              }
            }
          }
        }

        p, div, input, span {
          font-size: 16px;
        }
      }
    }
  }

  .popup-brochure {
    .popup-container {
      max-width: 700px;
      .content {
        border-radius: unset;
        background-color: $cl-white;
        padding: 24px;
  
        .title {
          text-transform: uppercase;
          font-size: 29px;

          @include media-maxW(768) {
            font-size: 24px;
          }
        }
  
        .field {
          width: 100%;
          max-width: 500px;
          margin: 24px auto auto;
          margin-top: 24px;
          
          &.error {
            input[type=text], input[type=email], input[type=number] {
              border-color: $cl-cardinal;
            }

            .error-msg {
              font-size: 13px;
              text-align: left;
              color: $cl-cardinal; 
              padding: 0 8px;       
            }
          }
  
          &.gender {
            margin-top: 60px;

            .radio-gender {
              display: flex;
              flex-wrap: wrap;

              .form-group {
                padding-left: 30px;
                margin-right: 24px;
                position: relative;

                .radio {
                  position: absolute;
                  left: -9999px;

                  &:not(:checked) + label:before,
                  &:checked + label:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 3px;
                    width: 20px;
                    height: 20px;
                    border: 1px solid #000;
                    border-radius: 100%;
                  }

                  &:checked + label:after {
                    content: '';
                    position: absolute;
                    left: 3px;
                    top: 6px;
                    width: 14px;
                    height: 14px;
                    background: $cl-cardinal;
                    border: 1px solid transparent;
                    border-radius: 100%;
                  }
                }
              }
            }
          }

          &.agreement-checkbox {
            text-align: left;
            padding-left: 60px;
            position: relative;
  
            .label {
              color: $cl-gray;
              font-size: 14px;
            }

            input[type=checkbox] {
              position: absolute;
              left: -9999px;

              &:not(:checked) + label:before,
              &:checked + label:before {
                content: '';
                position: absolute;
                left: 0;
                top: 3px;
                width: 40px;
                height: 40px;
                border: 1px solid #000;
              }

              &:checked + label:after {
                content: '';
                position: absolute;
                left: 12px;
                top: 7px;
                background: $cl-white;
                width: 16px;
                height: 24px;
                border: solid $cl-cardinal;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);            
              }

              &:checked + label {
                color: $cl-black;
              }
            }
          }

          input[type=text], input[type=email], input[type=number]  {
            width: 100%;
            height: 60px;
            border: 1px solid $cl-gray;
            padding: 20px;
            font-weight: 300;
            color:  $cl-gray;
            font-size: 15px;

            &::placeholder{
              color: $cl-shady-lady;
            }
          }
        }

        .submit-btn {
          margin-top: 40px;
          background-color: #bb2128;
          color: #fff;
          border-color: #bb2128;
          height: 48px;
          line-height: 48px;
          width: 320px;

          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
