#page-project {
	.navbar {
		margin-top: 40px;
		
		@include media-maxW(768) {display: none;}
		
    .container {
      .flex {
      	justify-content: center;
				margin: 0 -5px;
				
      	.item {
      		height: 40px;
      		background: url('../img/icon/mid-block.png') no-repeat center;
      		background-color: #fff;
      		background-size: calc(100% - 48px) 100%;
      		text-align: center;
      		position: relative;
      		padding: 0 5px;
					flex: 1;
					cursor: pointer;
					
      		&:before,
      		&:after {
      			content: '';
      			display: inline-block;
      			height: 100%;
      			width: 19px;
      			background: url('../img/icon/block.png');
      			background-size: 100% 100%;
      			position: absolute;
      			top: 0;
					}
					
      		&:before {
      			left: 6px;
					}
					
      		&:after {
  					transform: rotate(180deg);
  					right: 6px;
					}
					
      		&.active {
      			background: url('../img/icon/mid-block-active.png') no-repeat center;
						background-size: calc(100% - 48px) 100%;
						
      			&:before {
      				background: url('../img/icon/block-active.png');
      				background-size: 100% 100%;
						}
						
      			&:after {
      				background: url('../img/icon/block-active.png');
      				background-size: 100% 100%;
  						transform: rotate(180deg);
						}
						
      			h6 {color: $cl-white;}
					}
					
      		h6 {
   					@extend.center-xy;
   					width: 100%;
   					font-size: 1.8rem;
   					font-weight: $font-bold;
   					@include media-maxW(1300) {font-size: 1.6rem;}
      		}
      	}
      }
    }
	}

  .navbar-mb {
    display: none;
    @include media-maxW(768) {display: block;}
		
		.controls {
			margin: 0;
			text-transform: uppercase;

			.sort {
				width: 100%;
				border: none;
				height: 60px;

				.list {
					top: 60px;
					background: $cl-white;
					border: none;
					overflow-y: auto;

					&::-webkit-scrollbar {
						display: none;
					}
				}
	
				.option {
					width: auto !important;
					text-align: left;
					font-size: 16px;
					color: $cl-white;
					color: $cl-black;
					font-weight: 100;
					padding: 0px 40px;

					div {
						padding: 12px 20px;					
						border-bottom: solid 1px #E7E7EA;
					}

					&.active {
						background-color: $cl-black;

						div {
							border: none;
							color: $cl-white;
						}
					}
				}

				.current {
					padding-left: 30px;
					text-transform: uppercase;
					font-size: 20px;
					font-weight: 700;
				}
			}
		}
	}
	
	.project {
		.projects-container {
			display: none;
			margin: 40px 0;

			@include media-maxW(768) {
				margin: 0;
				padding-top: 10px;
				padding-bottom: 10px;
				background-color: #E7E7EA;
			};

			&.active {
				display: block;
			}

			.title {
				@include media-maxW(768) {display: none;}
			}

			.item {
				margin-top: 40px;
				@include media-maxW(768) {
					margin: 0;
					padding: 10px 0;
				}

				&:last-child() {
					.container {
						&>div {
							&:last-child() {
								border-bottom: none;
							}
						} 
					}
				}

				.container {
					&>div {
						&:first-child() {
							height: 470px;
							margin: 0 0 40px -55px;

							@include media-maxW(768) {
								margin: 0;
								height: auto;
							}

							.side-overview {
								width: 40%;
								text-align: center;
								padding: 0 55px;
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								@include media-maxW(768) {display: none;}

								.name {
									margin-bottom: 20px;
									width: 100%;
									font-size: 3rem;
									font-weight: $font-bold;
									line-height: 1.4;
								}

								.logo {
									margin-bottom: 20px;
									width: 100%;

									img {max-width: 180px;}
								}

								.short-des {
									font-size: 1.8rem;
									font-weight: $font-light;
									line-height: 1.5;
									margin-bottom: 20px;

									&.bold {
										font-weight: $font-bold;
									}
								}

								.detail-link {
									display: inline-block;

									button {
										@extend.btn;
										background: $cl-black;
										color: $cl-white;
										padding: 5px 30px;
										font-family: Open Sans;
										font-size: 1.8rem;
										font-weight: $font-light;
										border: none;
										box-sizing: border-box;
										margin-bottom: 10px;

										&:after {border: 1px solid $cl-black;}

										&:hover, &:focus{
											color: $cl-black;
											background-color: $cl-white;
											outline: 1px solid $cl-black;
										}
									}

									.tooltip {
										display: block;
										font-size: 1.3rem;
										width: 100%;
										padding: 20px 0 10px;
										text-align: center;
										background: url('../img/project/tooltip.png');
										background-size: 100% 100%;
										opacity: 0;
										max-width: 130px;
										margin: 0 auto;
									}
								}
							}
							.btm-overview {
								display: none;

								@include media-maxW(768) {
									display: flex;
									flex-direction: row;
									justify-content: center;
									order: 2;
									width: 100%;
									padding: 20px 10px;
									background-color: $cl-white;

									.logo {
										@include media-maxW(768) {
											width: 120px;
											margin: 0; 
											text-align: center;
											display: flex;
											align-items: center;
										}

										img {@include media-maxW(768) {width: 100%;}}
									}

									.info {
										width: calc(100% - 120px);
										display: flex;
										justify-content: center;
										flex-direction: column;
										padding-left: 15px;
										@include media-maxW(768) {padding-left: 40px;}

										.name {
											font-size: 1.6rem;
											font-weight: $font-bold;
											line-height: 1.5;
											margin-bottom: 10px;
										}

										.position {
											font-size: 1.4rem;
											line-height: 1.5;
										}

										.figures {
											display: none;
										}
									}
								}
							}

							.thumbnail {
								width: 60%;
								height: 100%;
								position: relative;
								overflow: hidden;

								@include media-maxW(768) {
									width: 100%;
									height: 400px;
									order: 1;
								}

								@include media-maxW(480) {
									height: 200px;
								}

								img {
									@extend.center-img;
									height: 100%;
								}
							}
						}

						&:last-child() {
							padding-bottom: 40px;
							border-bottom: 1px solid $cl-cardinal;
							@include media-maxW(768) {display: none;}
							&>div {
								width: 33.33%;
								padding: 0 30px;
								font-size: 1.8rem;
								font-weight: $font-light;
								line-height: 1.4;
								display: flex;
								figure {
									width: 20px;
									margin-right: 10px;
									img {width: 100%;}
								}
								span {
									width: calc(100% - 40px);
									font-size: 1.7rem;
								}
							}
						}
					}
				}
				&.animate {
					opacity: 1;
					.side-overview {
						animation-name: fadeInLeft;
						animation-duration: 2s;
					}

					.thumbnail {
						animation-name: fadeInRight;
						animation-duration: 2s;
					}
				}
			}
		}

		&.grid-view {
			@include media-minW(769) {
				.projects-container {
					width: 90%;
					margin: auto;
					grid-template-columns: calc(33.33% - 10px) calc(33.33% - 10px) calc(33.33% - 10px);
					grid-gap: 16px;
					margin-bottom: 5vw;

					@include media-maxW(1024) {
						grid-template-columns: calc(33.33% - 8px) calc(33.33% -8px);
					}

					&.active {
						display: grid;
					}

					.item {
						margin: 0;

						.container {
							padding: 0;
							background-color: #E7E7E8;
							height: 100%;

							&>div {
								&:first-child() {
									margin: 0;
									height: auto;

									.side-overview {
										display: none;
									}

									.btm-overview {
										display: flex;;
										flex-direction: row;
										order: 2;
										width: 100%;
										min-height: 240px;
										padding: 20px 10px;

										.logo {
											width: 120px;
											min-width: 120px;
											margin: 0 16px 0 8px; 
											text-align: center;
											display: flex;
											height: fit-content;

											img {
												width: 100%;
											}
										}

										.info {
											// flex-basis: 0;
											// flex-grow: 1;
											// display: flex;
											// flex-wrap: wrap;

											display: block;

											.name {
												font-size: 22px;
												font-weight: bold;
												line-height: 1.5;
												margin-bottom: 10px;
											}

											.position {
												display: none;
											}

											.figures {
												> div {
													font-size: 1.8rem;
													font-weight: 300;
													line-height: 1.4;
													display: flex;
													font-size: 16px;
													margin-top: 8px;

													figure {
														margin-right: 10px;
														width: 12px;

														img {
															margin-top: 4px;
															width: 100%
														}
													}

													span {
														flex-basis: 0;
														flex-grow: 1;
													}
												}
											}
										}
									}

									.thumbnail {
										width: 100%;
										height: 21vw;
										order: 1;
									}
								}

								&:last-child() {
									display: none;
									border: none;
								}
							}
						}
					}
				}
			}
		}
	}

	.view-config {
		margin: 40px 0;
		.view-options {
			text-align: center;
	
			.item {
				margin-left: 40px;
				font-size: 16px;
				cursor: pointer;

				span {
					vertical-align: middle;
					color: #E7E7EA;

					svg {
						fill: #E7E7EA;
						margin-right: 8px;
					}
				}

				&:hover, &:active, &.active {
					span {
						color: #858484;

						svg {
							fill: #858484;
						}
					}
				}
			}
		}

		@include media-maxW(768) {
			display: none;
		}
	}

	.nice-select-overlay {
		@include media-minW(769) {display: none;}
	}
}