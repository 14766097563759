#page-prize {
	.year {
		margin: 40px 0;
	  @include media-maxW(479) {margin: 20px 0};
	  .container {
	  	position: relative;
	    hr {
				position: absolute;
				bottom: 150px;
				left: 50%;
				transform: translateX(-50%);
				margin: 0 auto;
				width: 780px;
				height: 1px;
				border-bottom: none;
				color: $cl-black;
				z-index: 0;
				@include media-maxW(959) {
					width: 330px;
					bottom: 65px;
				}
				@include media-maxW(479) {width: 260px;}
			}
			.year-slider {
				margin: 20px auto 0;
				position: relative;
				width: 800px;
		  	padding: 0;
		    @include media-maxW(959) {
					width: 400px;
		    	padding: 0;
		    }
		    @include media-maxW(479) {
		    	width: 280px;
		    }
				.slick-list {
					padding: 0;
					.year-item {
						position: relative;
	    			cursor: pointer;
	    			z-index: 2;
	    			&.active {
	    				.icon {
								background: url('../img/bg/hexagon-hover.jpg') center no-repeat;
								background-size: calc(100% - 20px) auto;
	    				}
	    			}
	    			.icon {
							position: relative;
							width: 200px;
							height: 300px;
							padding: 10px;
							background: url('../img/bg/hexagon.jpg') center no-repeat;
							background-size: calc(100% - 20px) auto;
							color: $cl-black;
							z-index: 2;
							@include media-maxW(959) {height: 130px;}
		    			&:after {
		    				content: "";
		    				display: block;
		    				padding-bottom: 100%;
		    			}
		    			span {
		    				@extend.center-xy;
		    				font-weight: $font-bold;
		    				opacity: 0.8;
		    			}
		    			figure {
		    				@extend.center-xy;
		    				width: 35%;
		    				img {
		    					width: 100%;	
		    					opacity: 0.1;
		    				}
		    			}
	    			}
					}
				}
			}

	    .slick-prev,
		  .slick-next {
		  	width: 50px;
		  	z-index: 1;
				@extend.center-y;
				&:before,
				&:after {
					background-color: $cl-black;
					@include media-maxW(959) {height: 30px;}
					@include media-maxW(479) {height: 20px;}
				}
		  }
			.slick-prev {
		    @extend.slick-prev;
		    left: -50px;
				@include media-maxW(959) {
		    	&:before {top: -20px;}
				}
				@include media-maxW(479) {
					left: -35px;
					&:before {top: -10px;}
				}	
		  }
		  .slick-next {
		    @extend.slick-next;
		    right: -50px;
		    @include media-maxW(959) {
		    	&:before {top: -20px;}
				}
				@include media-maxW(479) {
					right: -30px;
					&:before {top: -10px;}
				}
		  }
	  }
	}
	.prize {
		.container {
			.big-item {
				display: none;
				justify-content: center;
				&.active {display: flex;}
	    	.item {
	    		width: 25%;
	    		padding: 0 20px;
	    		display: flex;
					align-items: center;
					margin-bottom: 50px;
					@include media-maxW(959) {width: 50%;}
		    	figure {
		    		width: 100%;
		    		cursor: pointer;
		    		img {
		    			width: 100%;
		    			max-width: 180px;
		    			display: block;
		    			margin: 0 auto;
		    			@include media-maxW(959) {max-width: 120px;}
		    		}
		    	}
	    	}
			}

    	.prize-des{
        display: none;
        position: relative;
        margin: 0 auto 20px;
        width: 90%;
        background-color: $cl-iron;
        border: none;
        border-radius: 30px;
        padding: 30px;
        @include media-maxW(768) {padding: 20px;}
        @include media-maxW(479) {
          width: 95%;
          padding: 40px 10px;
        }
        .close {
          position: absolute;
          top: 10px;
          right: 10px;
          display: inline-block;
          width: 25px;
          height: 25px;
          overflow: hidden;
          cursor: pointer;
          &:before,
          &:after {
            content: '';
            position: absolute;
            height: 2px;
            width: 100%;
            top: 50%;
            left: 0;
            margin-top: -1px;
            background: #000;
          }
          &:before {transform: rotate(45deg);}
          &:after {transform: rotate(-45deg);}
        }
        figure {
        	width: 100%;
        	text-align: center;
        	margin: 40px 0;
        	@include media-maxW(767) {margin: 20px 0;}
        	img {
        		width: 100%;
        		max-width: 500px;
        	}
        }
        .title-des {
        	font-size: 2.2rem;
        	line-height: 1.5;
        	font-weight: $font-bold;
        	margin-bottom: 20px;
        	text-align: center;
        	@include media-maxW(959) {font-size: 1.6rem;}
        }
        .content-des {
        	font-size: 1.8rem;
        	line-height: 1.5;
        	text-align: justify;
        	text-align-last: center; 
        	@include media-maxW(959) {font-size: 1.4rem;}
        }
      }
		}
	}
}