#page-policy {
  .policy {
    max-width: 1100px;
    margin: 100px auto;
    @include media-maxW(1200) {margin: 60px 0};
    @include media-maxW(480) {margin: 40px 0};
    .container {
      .big-item {
        margin-bottom: 80px;
        @include media-maxW(767) {margin-bottom: 40px;}
        &.type-1 {
          .content {
            margin: 0 -40px;
            .item {
              padding: 0 40px;
            }
          }
        }
        &.type-2 {
          .content {
            padding-bottom: 0;
            justify-content: center;
            .item {
              flex-grow: unset;
              flex-basis: unset;
              width: 33.33%;
              margin-bottom: 50px;
            }
          }
        }
        .content {
          padding: 30px 30px 50px 30px;
          margin: 0 -15px;
          @include media-maxW(767) {
            padding: 10px;
            margin: 0 -5px;
          }
          .item {
            flex-grow: 1;
            flex-basis: 0;
            max-width: 600px;
            padding: 0 15px;
            text-align: center;
            @include media-maxW(767) {
              width: 50%;
              flex-grow: unset;
              flex-basis: unset;
              padding: 0 5px;
              margin-bottom: 40px;
            }
            figure {
              width: 100%;
              margin-bottom: 40px;
              @include media-maxW(767) {margin-bottom: 20px;}
              img {
                width: 100%;
                max-width: 100px;
                @include media-maxW(767) {max-width: 80px;}
              } 
            }
            .title-item {
              font-size: 2.2rem;
              font-weight: $font-bold;
              line-height: 1.5;
              text-align: center;
              margin-bottom: 40px; 
              @include media-maxW(767) {
                font-size: 1.4rem;
                text-align: center;
                margin-bottom: 0;
              }
            }
            .description-item {
              font-size: 1.8rem;
              font-weight: $font-light;
              line-height: 1.5;
              text-align: justify;
              text-align-last: left;
              @include media-maxW(767) {display: none;}
            }
          }
        }
      }
    }
  }
}