#page-regular-report {
  .regular-report {
    margin: 40px 0;
    @include media-maxW(480) {margin: 20px 0};
    .container {
    	.list {
    		display: flex;
    		flex-wrap: wrap;
    		margin: 40px -20px 0;
	    	.item {
	    		width: 33.33%;
	    		padding: 0 20px;
	    		margin-bottom: 40px;
	    		@include media-maxW(767) {width: 50%;}
	    		figure {
	    			width: 100%;
	    			text-align: center;
	    			&.thumbnail {
	    				margin-bottom: 20px;
	    				@include media-maxW(767) {margin-bottom: 10px;}
	    				img {
								width: 100%;
	    					max-width: 200px;
	    					box-shadow: 5px 10px 8px #888888
	    				}
	    			}
	    			&.icon {
	    				img {
								width: 100%;
	    					max-width: 35px;
	    				}
	    			}
	    		}
	    		.title-report {
	    			margin-bottom: 20px;
	    			text-align: center;
	    			font-size: 1.6rem;
	    			line-height: 1.5;
	    			@include media-maxW(767) {
	    				font-size: 1.4rem;
							margin-bottom: 10px;
	    			}
	    		}
	    	}
    	}
    }
  }
}