.subscribe {
  .main-footer-title {
    margin-bottom: 7px;
  }
}

.subscribe-form {
  margin: 0 -10px;

  @include media-maxW(768) { 
    margin: 0;
  }

  &__submit {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border-left: 8px solid $cl-cardinal;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: none;
    background: none;
    padding: 0;
    font-size: 0;
    cursor: pointer;

    @include media-maxW(768) { 
      position: relative;
      top: 0;
      transform: unset;
      border: 1px solid $cl-black;
      background: $cl-white;
      width: 100%;
      margin: 10px 0;
      padding: 10px;
      font-size: 1.3rem;
      text-align: center;
      text-transform: uppercase;
    }
  }
}